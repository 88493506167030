
.form-content{
	.form-group{
		@include flex("row-top-wide",wrap,1rem);
		width: 50%;
		margin: 0 auto;

		.col{
			width: calc(50% - 1rem);
			@include flex("column-center-top",wrap,1rem);
		}
		input,
		textarea,
		.select{
			position: relative;
			display: inline-block;
			// margin: 2rem 0 0;
			padding: 1rem 1rem;
			width: calc(100% - (2rem + 4px));
			font-size: 1rem;
			font-family: inherit;
			color: $c3-green;
			border: solid 2px $c3-green;
			border-radius: 1.5rem;
			background-color: $c3-dgray;
		}

		input[type='text']::placeholder,
		input[type='email']::placeholder,
		textarea::placeholder{
			color: #aaa;
		}

		select{
			display: none;
		}
		.select{
			cursor: pointer;
			z-index: 1;
			color:$c3-green;
			.arrow{
				position: absolute;
				display: block;
				top:1rem;
				right: 1rem;
				width: 1.2rem;
				height: 1.2rem;
				@include bg-image('arrow-down.svg',100%,inherit,initial,no-repeat);

			}
			.picked{
				display: block;
				white-space: nowrap; 
			  width: calc(100% - 2rem); 
			  overflow: hidden;
			  text-overflow: ellipsis; 
			}
			.list{
				display: none;
				position: absolute;
				width: 120%;
				height: 10rem;
				left: 0;
				bottom: -10.5rem;
				font-size: 1rem;
				font-family: inherit;
				color: $c3-green;
				border: solid 1px $c3-gray;
				background-color: $c3-dgray;
				box-shadow: 0 .5rem 1rem rgb(0 0 0 / .5);
				ul{
					margin: 0;
					padding: 1rem;
					height: calc(100% - 2rem);
					overflow-y: auto;
					li{
						margin: 0;
						padding: .5rem;
						list-style: none;
						color: $c3-green;
						transition: all .3s cubic-bezier(1, 0.08, 0.35, 1);
						&:hover{
							background-color: $c3-green;
							color: $c3-dgray;
						}
					}
				}
				&.show{
					display: block;
				}
			}
			&[data-status="def"]{
				color: #aaa;
			}
		}

		textarea{
			resize: none;
			height: 3rem;
			margin: 0;
			overflow: hidden;
		}

		.recaptcha{
			position: absolute;
			right: 0;
			visibility: hidden;
		}
		label{
			width: 100%;
			text-align: center;
			font-weight: 700;
			font-size: calc(.2rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
			text-transform: uppercase;
			color: $c3-gray;
		}

		.email-notif{
			margin-top: 1rem;
			display: block;
			padding: .5rem .25rem;
			background-color: red;
			font-size: calc(.2rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
			font-weight: 500;
			text-transform: uppercase;
			border-radius: 100vmax;
			background-color: $c3-green;
			line-height: 1;
			opacity: 0;

			transition: .3s opacity cubic-bezier(1, 0.08, 0.35, 1);
			&.error{
				background-color: red;
			}
			&.show{
				opacity: 1;
			}
		}
		&.light{
			input{
				color: $c3-gray;
				background-color: #fff;
			}
		}
	}
}
.inner .form-content{
	padding-top: 5rem;
} 

@media (max-width: 640px){
	.form-content{
		.form-group{
			margin-top: 2rem;
			@include flex("column-top-center",nowrap,0);
			width: 80%;
			.col{
				gap:0;
				width: 100%;
				margin-top: 0;
			}
			input,
			.select{
				padding: .5rem 1rem;
				height: 20px;
				width: calc(100% - 2rem);
				margin: .5rem 0;
				.arrow{
					top:.5rem
				}
				.list{
					width: 100%;
				}
			}
			textarea{
				margin: .5rem 0;
			}

			.email-notif{
				opacity: 0;
				margin: 0 auto 1rem !important;
				font-size: calc(.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
			}

			label{
				font-size: calc(.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
			}
		}
	}
}