$media-string: "only screen and ";
$smallmobile: $media-string+"(max-width: 320px)";
$tablet: $media-string+"(max-width: 768px)";
$large-tablet: $media-string+"(max-width: 1024px)";
$large-desktop: $media-string+"(min-width: 1200px)";
$larger-desktop: $media-string+"(min-width: 1400px)";
$largest-desktop: $media-string+"(min-width: 1860px)";
$l4k-desktop: $media-string+"(min-width: 3000px)";

$media-tablet: 640px;
$media-largeTablet: 768px;
$media-desktop: 1024px;
$media-largeDesktop: 1440px;

@media (max-width: $media-desktop) {
	:root{
		--base-font-size: 14px;
	}

}

@media (min-width: 768px) and (max-width: 1200px) {
	main{
		section.who,
		section.approach,
		section.purpose{
			.content{
				display: none;
			}
		}
	}
}

@media (max-width: $media-largeTablet) {
	:root{
		--base-font-size: 16px;
	}

	header, header.dark{
		top:0;
		height: 5rem;
		backdrop-filter:blur(5px);
		background-color: rgb(255 255 255 / .3);
	}header.dark{
		background-color: rgb(51 62 72 / .3);
	}

	aside{
		display: none;
	}

	main{
		// display: none;
		&.secondary{
			box-shadow: 0 -1rem 2rem 2rem rgb(51 62 72 / 1);
		}

		.cross{
			width: 2.5rem;
			height: 2.5rem;
			&:nth-of-type(1),
			&:nth-of-type(2){
				top: 5rem;
			}
		}

		.three{
			display: none;
		}

		section.dark{
			margin-top: -2px;
			border-top: solid 1px #333e48;
		}

		section.banner{
			height: 150vh;
			.content{
				@include flex("column-top-center",nowrap,0);
				h1{
					position: relative;
					padding-top:5rem;
					width: calc(100% - 2rem);
					img{
						width: 10rem;
					}
				}
				h2.mobile{
					display: inline-block;
					padding: 0 2rem;
					font-size: calc(1.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
				}
				h2.desk{
					display: none;
					em{
						display: none;
					}
				}
				.arrow-indicator{
					position: absolute;
					display: block;
					top: 75vh;
					left: calc(50% - .225rem);
					width: .45rem;
					height: 5rem;
					background-color: $c3-gray;

 					animation-name: scroll-indicator-line-mobile;
					animation-duration: .8s;
					animation-direction: alternate;
					animation-iteration-count: infinite;
					animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
					z-index: 1;
					&:after{
						content: '';
						position: absolute;
						display: block;
						left: calc(50% - 2rem);
						bottom: -2rem;
						width: 4rem;
						height: 2.3rem;
						@include bg-image('arrow-head.svg');

						animation-name: scroll-indicator-arrow-mobile;
						animation-duration: .8s;
						animation-direction: alternate;
						animation-iteration-count: infinite;
						animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
						z-index: 1;
					}
				}
			}

			.marquee-clouds-1-outer,
			.marquee-clouds-2-outer,
			.marquee-clouds-3-outer{
				top:15vh;
				.marquee-clouds-1-inner,
				.marquee-clouds-2-inner,
				.marquee-clouds-3-inner{
					width: 2000vw;
					@include bg-image('clouds-1.svg',500vw 400px,inherit,initial,repeat-x);

					--offset: 250vw;
		    	--move-initial: calc(-25% + var(--offset));
		    	--move-final: calc(-50% + var(--offset));

					animation-name: marquee;
					animation-duration: 30s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}
			}

			.marquee-clouds-2-outer{
				top:20vh;
				.marquee-clouds-2-inner{
					animation-duration: 50s;
				}
			}

			.marquee-clouds-3-outer{
				top: 10vh;
				height: 100vh;
				.marquee-clouds-3-inner{
					@include bg-image('clouds-3.svg',500vw 500px,inherit,initial,repeat-x);
					opacity: .08;
					filter: blur(2px);

					animation-duration: 80s;
				}
			}

			.trees,.bldg-1,.bldg-2,.bldg-3,.bldg-4,.bldg-5{
				bottom: 8%;
				@include bg-image('trees.png',200% auto,inherit,center bottom);
				z-index: 4;
			}

			.bldg-1{
				bottom: 17%;
				@include bg-image('bldg-1.png',200% auto,inherit,center bottom);
				z-index: 3;
			}

			.bldg-2{
				bottom: 17%;
				@include bg-image('bldg-2.png',200% auto,inherit,center bottom);
				z-index: 2;
			}

			.bldg-3{
				bottom: 30%;
				@include bg-image('bldg-3.png',200% auto,inherit,center bottom);
				z-index: 1;
			}

			.bldg-4{
				bottom: 35%;
				@include bg-image('bldg-4.png',200% auto,inherit,center bottom);
				z-index: 0;
			}

			.bldg-5{
				margin-left: -5%;
				height: 100vh;
				bottom: -10%;
				@include bg-image('bldg-5.svg',200% auto,inherit,center bottom);
				z-index: 5;
			} 
		}

		section.who,
		section.approach,
		section.purpose{
			isolation: isolate;
			@include flex("column-center-center",nowrap,1rem);
			height: 100vh;
			.content{
				@include flex("column-center-top",nowrap,1rem);
				margin-top: 1rem;
				z-index: 1;
				div{
					margin-top: 5rem;
					width: 80%;
					a{
						display: block;
						margin-top: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
						padding: 1rem 2rem;
						border-radius: 100vmax 100vmax 100vmax 0;
						background-color: rgb(51 62 72 / .5);
						backdrop-filter:blur(5px);
						color: #fff;

						transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
						&:hover{
							color: $c3-gray;
							background-color: $c3-green;
						}
					}
				}
			}
			.three{
				position: absolute;
				display: block;
				inset:0;
				z-index: -1;
			}
		}

		section.who{
			a{
				margin-top: 0;
			}
			.content{
				@include flex("column-center-center",nowrap,1rem);
				padding-bottom: 0;
				div{
						margin: 0 auto;
						@include flex("column-top-center",wrap,0rem);
					p{
						margin-bottom: .5rem;
					}
					ul{
						margin:0;
						padding: .5rem;
						@include flex("row-center-top",wrap,0);
						width: calc(100% - 2rem);
						li{
							margin: 0 .5rem .25rem;
							display: block;
							width: calc((100% / 3) - 1rem);
							font-size: calc(.5rem + 12 * (100vw - 300px) / 1300);
							font-weight: 300;
							text-transform: uppercase;
							text-align: center;
							color: #fff;
							&:before{
								content: '';
								display: block;
								margin: 0 auto;
								width: calc(5rem + 12 * (100vw - 300px) / 1300);
								height: calc(5rem + 12 * (100vw - 300px) / 1300);
								border-radius: 100vmax;
							}
							@for $i from 1 through 5 {
								$img: 'who/who-'+$i+'.png';
								&:nth-of-type(#{$i}):before{
									@include bg-image(#{$img},50%,inherit,center,no-repeat,rgb(51 62 72 / 0.5));
								}
							}
						}
					}
				}
			}
			.three{
				opacity: .3;
			}
		}

		section.teams{
			padding-top: 15vh;
			// height: 100vh;
			height: auto;
			.marquee-title{
				// display: none;
				margin-top: 0;
				padding-top:0;
				height: 2.2rem;
				font-size: 2rem;
				.marquee-txt{
					width: 3600px;
					height: 2.2rem;
					--offset: 450px;
			    --move-initial: calc(-25% + var(--offset));
			    --move-final: calc(-50% + var(--offset));

					animation-name: marquee;
					animation-duration: 15s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					span{
						width: 1800px;
					}
					span:nth-of-type(2){
						left: 1800px;
					}
				}
			}
			.content{
				// display: none;
				height: auto;
				.col-container{
					@include flex("column-top-center",nowrap,1rem);
					.col:not(.team-pics){
						position: relative;
						width: calc(80% - 2rem);
						z-index: 0;
						text-align: right;
						p{
							margin-top: 1rem;
							text-align: center;
						}
					}
					.view-all{
						margin-top: 0;
						max-width: 80%;
						a{
							font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
							margin-top: 0;
						}
					}
				}
			}
		}

		section.branches{
			padding-top: 10vh;
			// height: 100vh;
			height: auto;
			.content{
				height: 60vh;
				@include flex("row-center-top",nowrap,0);
				margin-top: 10vh;
				div{
					@include flex("row-center-center",nowrap,0);
					height: 40%;
					h3{
						width: 80%;
					}
					.bldg-3,.bldg-4{
						display: none;
					}
				}
			}
			.branch-link{
				top:0;
				padding-top:30vh;
				.col-container{
					@include flex("column-center-center",nowrap,0);
					height: 60vh;
					.col{
						width: calc(100% - 2rem);
						a{
							padding: 1rem;
							width: 80%;
							margin-top: 0;
							font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
							text-align: center;
						}
					}
				}
			}
		}

		section.clients{
			padding-top: 10vh;
			// height: 100vh;
			height: 60vh;
			.content{
				// display: none;
				// margin-top: 10vh;
				margin-top: 5rem;
				width: 80%;
				height: 60vh;
				.text-content{
					margin-top: 10vh;
					width: 100%;
					max-width: 100%;
				}
				.slider{
					// width: 500vw;
					width: 100vw;
					margin-top: 5rem;
					.slider-inner{
						padding: 0;
						top: calc(50vh - 50px);
						height:  calc((100vw / 3) - 1rem);
						// width:calc(100% - 100px);
						width: 100%;
						margin-left:0;
						// justify-content: space-around;
						.slick-slide,
						.item-overlay{
							width: calc((100vw / 3) - 1rem);
							height: calc((100vw / 3) - 1rem);
							// width:80vw;
							// height: 100%;
							.img{
								width: 100%;
							}
						}
					}
				}
				.arrow-left,.arrow-right{
					top: calc(50vh - 50px + 5rem);
					width: 50px;
					height: calc((100vw / 3) - 1rem);
					background-size: 20px;
					&:hover{
						background-color: transparent;
					}
				}
			}
		}

		section.works{
			padding-top: 10vh;
			height: 100vh;
			.content{
				margin-top: 10vh;
				.text-content{
					// margin-top: 10vh;
					width: 80%;
					max-width: 80%;
				}
				.slider{
					margin-top: 5vh;
					// width: 500vw;
					width: 100vw;
					.slider-inner{
						padding:0;
						top: calc(40vh - 50px);
						margin: 0;
						width:100%;
						height: 30%;
						justify-content: space-around;
						.slick-slide,
						.item-overlay{
							width:100vw;
							height: 300px;
							.img{
								// left:-5%;
								// width: 110%;
								left: 0;
								width: 100%;
								filter:none;
								background-position: center;
							}
							.bubble{
								display: none;
							}
						}
					}
				}
				.arrow-left,.arrow-right{
					top: calc(40vh - 50px);
					margin-top: 5vh;
					width: 50px;
					height: 30%;
					background-size: 20px;
					&:hover{
						background-color: transparent;
					}
				}
			}
		}

		section.contact{
			padding-top: 10vh;
			height: 100vh;
			.content{
				position: relative;
				margin-top: 0;
				height: auto;
				z-index: 2;
				.form-content{
					margin-top: 0;
					p{
						width: 80%;
					}
				}
				.btn{
					margin-top: 1rem;
				}
			}
			@include bg-image('grid-2.svg',50px 50px,inherit,initial,repeat,$c3-gray);
			&:before,&:after{
				content: '';
				position: absolute;
				display: block;
				inset:0;
				@include bg-image('none',100%,inherit,initial,no-repeat,none,true,$c3-gray,#1a1a1a,#1a1a1a);
				z-index: 1;
			}
			&:after{
				@include bg-image('grid-2.svg',50px 50px,inherit,initial,repeat,transparent);
			}
		}

	}

	footer{
		// display: none;
		width: calc(100% - 2rem);
		height: auto;
		max-height: initial;
		padding-bottom: 10vh;
		.map{
			height: 30vh;
		}
		.content{
			padding-top: 0;
			@include flex("column-top-center",nowrap,0);
			.side-col{
				&:nth-of-type(1){
					img{
						width: 100%;
					}
				}
				&:nth-of-type(3){
					padding: 1rem;
					width: calc(100% - 2rem);
					border: none;
					ul{
						display: none;
					}
					.return{
						display: block;
						padding: .5rem;
						width: 100%;
						font-weight: 700;
						text-transform: uppercase;
						text-align: center;
						border-radius: 100vmax 100vmax 100vmax 0;
						background-color: $c3-green;
						color: $c3-gray;
					}
				}
			}
			.mid-col{
				margin-top: 0;
				padding: 0 1rem;
				width: calc(80% - 2rem);
				@include flex("column-top-center",nowrap,0);
				.col{
					padding: 0 1rem;
					width: 100%;
					p.title,p.address{
						margin-bottom: .5rem;
					}
					&.twin{
						.address{
							padding: 0;
						}
					}
					&.twin:nth-of-type(1){
						padding-right: 1rem;
					}
					&.twin:nth-of-type(2){
						.title{
							display: none;
						}
						padding-left: 1rem;
					}
				}
			}
		}
		.bot-content{
			margin-top: 0;
		}
		>*{
			flex-grow: 1;
			flex-basis: 100%;
		}
	}
}