/*VARIABLES*/
$full-width-desk: calc(100% - 1rem);
$full-height-desk: calc(100vh - 5.5rem);
$full-width-mob: calc(100% - 4rem);
$full-height-mob: calc(100vh - 7rem);


$gap: 0.5rem;
$gap-1: 1rem;
$gap-1h: 1.5rem;
$gap-2: 2rem;
$gap-2h: 2.5rem;
$gap-3: 3rem;
$gap-3h: 3.5rem;
$gap-4: 4rem;
$gap-4h: 4.5rem;
$gap-5: 5rem;
$gap-5h: 5.5rem;

/*DOMS*/
* {
	margin: 0;
	padding: 0;
}

html,body{
	position:relative;

	margin: 0;
	padding: 0;

	font-size: var(--base-font-size);
	font-family: var(--base-font);
}
body{
	overflow: hidden;
}
@media (max-width: 768px) {
	body{
		overflow-y:auto;
	}
}
body.inner{
	overflow-y:auto;
}

// containers
header{
	position: fixed;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	z-index: 999;
}

main{
	position:relative;

	margin:0;
	padding: 0;

	section{
		position: relative;		
		font-family: inherit;
		overflow: hidden;
		outline: none;
		.content{
			width: 100%;
			height:100vh;
			margin:0 auto;
			max-width: 1200px;
		}
	}
}

aside{
	position: relative;

	margin: 0;
	padding: 0;

	font-family: inherit;

	.container{
		padding: $gap-1;
	}
}

footer{
	position: relative;

	margin: 0;
	padding: 0;

	font-family: inherit;

	.container{
		padding: $gap-1;
	}
}

@mixin shadow{
	box-shadow: 0 .3rem 1rem alpha($c3-dgray,0.3);
}

/*DISPLAY FLEX */
@mixin flex($behavior, $wrap: nowrap, $gap:$gap){
	$justify:flex-start;
	$align:flex-start;
	$orientation: row;
	$spacing: flex-start;

	@if($behavior == "row-top-left"){
		$orientation: row;
	}
	@else if($behavior == "row-top-right"){
		$orientation: row;
		$justify:flex-end;
	}
	@else if($behavior == "row-bottom-left"){
		$orientation: row;
		$spacing:flex-end;
	}
	@else if($behavior == "row-bottom-center"){
		$orientation: row;
		$justify:center;
		$spacing:flex-end;
	}
	@else if($behavior == "row-bottom-wide"){
		$orientation: row;
		$justify:space-between;
		$spacing:flex-end;
	}
	
	@else if($behavior == "row-top-left-full"){
		$orientation: row;
		$align: stretch;
	}
	@else if($behavior == "row-center-top"){
		$orientation: row;
		$align:flex-start;
		$justify:center;
		$spacing:flex-start;
	}
	@else if($behavior == "row-top-right-full"){
		$orientation: row;
		$justify:flex-end;
		$align: stretch;
	}
	@else if($behavior == "row-center-left"){
		$orientation: row;
		$spacing:center;
		$align:center;
	}
	@else if($behavior == "rev-row-center-left"){
		$orientation: row-reverse;
		$spacing:center;
		$align:center;
	}
	@else if($behavior == "row-center-center"){
		$orientation: row;
		$align:center;
		$justify:center;
		$spacing:center;
	}
	@else if($behavior == "row-top-wide"){
		$orientation: row;
		$justify:space-between;
	}
	@else if($behavior == "row-center-wide"){
		$orientation: row;
		$align:center;
		$justify:space-between;
	}
	@else if($behavior == "row-center-even"){
		$orientation: row;
		$align:center;
		$justify:space-around;
	}
	@else if($behavior == "row-center-full"){
		$orientation: row;
		$spacing: stretch;
	}
	@else if($behavior == "column-top-left"){
		$orientation: column;
	}
	@else if($behavior == "column-center-left"){
		$orientation: column;
		$justify: center;
	}
	@else if($behavior == "column-center-right"){
		$orientation: column;
		$align:flex-end;
		$justify: center;
	}
	@else if($behavior == "column-top-wide"){
		$orientation: column;
		$justify: space-between;
	}
	@else if($behavior == "rev-column-top-left"){
		$orientation: column-reverse;
		$justify: flex-end;
	}
	@else if($behavior == "column-top-center"){
		$orientation: column;
		$align: center;
	}
	@else if($behavior == "column-bottom-center"){
		$orientation: column;
		$align: center;
		$justify: flex-end;
		$spacing: center;
	}
	@else if($behavior == "column-center-center"){
		$orientation: column;
		$align: center;
		$justify: center;
	}

	display: flex;
	flex-flow: $orientation $wrap;
	justify-content: $justify;
	align-items: $align;
	align-content:$spacing;
	gap:$gap;
}


/*BACKGROUND*/
@mixin bg-image($src, $size: 100%, $attachment: inherit, $position: initial, $repeat: no-repeat, $color:transparent, $gradient:false, $gDirection:to bottom, $gColor1:rgb(255 255 255 / 1), $gColor2:rgb(0 0 0 / 1)){
	$path:'../img/'+$src;
	@if($src != 'none' and $gradient){
		background-image:url($path),linear-gradient($gDirection,$gColor1,$gColor2);
	}@else if($src == 'none' and $gradient){
		background-image:linear-gradient($gDirection,$gColor1,$gColor2);
	}@else{
		background-image:url($path);
	}
	background-size:$size;
	background-position: $position;
	background-attachment: $attachment;
	background-repeat: $repeat;
	background-color: $color;
}