header{
	.reso{
		position: fixed;
		// display: none;
		text-align: center;
		width: 100%;
		background-color: #000;
		z-index: 9999;
		font-size: .8rem;
		color: #fff;
	}

	top:-10rem;
	width: 100%;
	transition: top .5s cubic-bezier(1, 0.08, 0.35, 1);
	.logo{
		position: absolute;
		top:.5rem;
		left: 1rem;
		width: 4rem;
		z-index: 11;

		transition: opacity .3s .5s cubic-bezier(1, 0.08, 0.35, 1);
		&.dark{
			opacity: 1;
		}
		&.light{
			opacity: 0;
		}
	}
	&.turn-white,&.dark{
		height: 0;
		.logo.light{
			opacity: 1;
		}
		.logo.dark{
			opacity: 0;
		}
	}
	.menu{
		position: absolute;
		top:1.2rem;
		right: 1.2rem;
		width: 2.2rem;
		height: 2rem;
		cursor: pointer;
		z-index: 11;
		span{
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height: .2rem;
			background-color: $c3-gray;

			&:before{
				content: '';
				position: absolute;
				display: block;
				right: 0;
				top:0;
				height: 100%;
				width: 0;
				background-color: $c3-green;
			}
		}
		
		span:nth-of-type(1){
			top:.4rem;
			left: 0rem;
			transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
			&:before{
				transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
			}
		}
		span:nth-of-type(2){
			top:1.15rem;
			left:0;
			transition: all .5s .1s cubic-bezier(1, 0.08, 0.35, 1);
			&:before{
				transition: all .5s .1s cubic-bezier(1, 0.08, 0.35, 1);
			}
		}
		span:nth-of-type(3){
			top:2rem;
			left:0;
			transition: all .5s .2s cubic-bezier(1, 0.08, 0.35, 1);
			&:before{
				transition: all .5s .2s cubic-bezier(1, 0.08, 0.35, 1);
			}
		}
		&.dark span,&.show span{
			background-color: #fff;
		}
		&.show span:nth-of-type(1){
			transform: rotate(45deg);
			top:.75rem;
			left: 1.2rem;
			width: 50%;
		}
		&.show span:nth-of-type(2){
			transform: rotate(360deg);
		}
		&.show span:nth-of-type(3){
			transform: rotate(-45deg);
			top:1.6rem;
			left: 1.2rem;
			width: 50%;
		}
		&:hover span:before{
			width: 100%;
		}
	}
}

.show header{
	top:0;
}

.inner header{
	top:0;
	height: 5rem;
	background-color: #fff;

	transition: height .3s .5s cubic-bezier(1, 0.08, 0.35, 1);
	&.turn-white{
		height: 0;
	}
}