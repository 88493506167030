main{
	width: 100%;
	height: auto;
	&.primary{
		z-index: 0;
	}
	&.secondary{
		z-index: 2;
		background-color: #fff;
		box-shadow: 0 -5rem 11rem 11rem rgb(51 62 72 / 1);
	}

	.cross{
		position: fixed;
		display: block;
		width: 4rem;
		height: 4rem;
		@include bg-image('cross.svg');
		z-index: 999;
		&:nth-of-type(1){
			top: 1rem;
			left: 1rem;
		}
		&:nth-of-type(2){
			top: 1rem;
			right: 1rem;
		}
		&:nth-of-type(3){
			bottom: 1rem;
			left: 1rem;
		}
		&:nth-of-type(4){
			bottom: 1rem;
			right: 1rem;
		}
		transition: top .5s, bottom .5s cubic-bezier(1, 0.08, 0.35, 1); 
	}

	.three{
		position: fixed;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	section.light{
		@include bg-image('grid.svg',50px 50px,inherit,initial,repeat);
	}

	section.dark{
		@include bg-image('grid-2.svg',50px 50px,inherit,initial,repeat);
	}

	section.preloader{
		position: fixed;
		@include flex("row-center-center",nowrap,0); 
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: #fff;
		.percentage{
			@include flex("row-center-center",nowrap,0);
			font-family: $font-arvo;
			font-size: 5rem;
			color: $c3-green;
			small{
				font-size: 2rem;
				color: $c3-gray;
			}
		}
	}

	section.banner{
		margin: 0 auto;
		width: 100%;
		height: 250vh;
		isolation: isolate;
		.content{
			@include flex("row-center-left",nowrap,0);
			h1{
				position: absolute;
				top: 0;
				margin: 0;
				padding: 1rem;
				width: 100%;
				max-width: calc(1200px - 2rem);
				text-align: center;
				img{
					display: inline-block;
					width: 10%;
					max-width: 15rem;
				}
			}
			h2.desk,h2.mobile{
				display: inline-block;
				padding: 2rem;
				em{
					position: relative;
					display: inline-block;
					font-style: normal;
					font-weight: 700;
					color: #fff;
					&:before{
						content: '';
						position: absolute;
						display: block;
						top: .75rem;
						left: calc(50% - .225rem);
						width: .45rem;
						height: 10rem;
						background-color: $c3-gray;

	 					animation-name: scroll-indicator-line;
						animation-duration: .8s;
						animation-direction: alternate;
						animation-iteration-count: infinite;
						animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
						z-index: 1;
					}
					&:after{
						content: '';
						position: absolute;
						display: block;
						left: calc(50% - 2rem);
						bottom: -2rem;
						width: 4rem;
						height: 2.3rem;
						@include bg-image('arrow-head.svg');

						animation-name: scroll-indicator-arrow;
						animation-duration: .8s;
						animation-direction: alternate;
						animation-iteration-count: infinite;
						animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
						z-index: 1;
					}
				}
			}
			h2.mobile,
			.arrow-indicator{
				display: none;
			}
		}

		.marquee-clouds-1-outer,
		.marquee-clouds-2-outer,
		.marquee-clouds-3-outer{
			position: absolute;
			top:50vh;
			width: 100%;
			height: 100vh;
			overflow: hidden;

			.marquee-clouds-1-inner,
			.marquee-clouds-2-inner,
			.marquee-clouds-3-inner{
				width: 400vw;
				height: 100%;
				@include bg-image('clouds-1.svg',100vw 200px,inherit,initial,repeat-x);
				transform: translate3d(var(--move-initial), 0, 0);
				opacity: .3;

				--offset: 50vw;
		    --move-initial: calc(-25% + var(--offset));
		    --move-final: calc(-50% + var(--offset));

				animation-name: marquee;
				animation-duration: 30s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
			}
		}

		.marquee-clouds-2-outer{
			top: 40vh;
			height: 100vh;
			.marquee-clouds-2-inner{
				@include bg-image('clouds-2.svg',100vw 300px,inherit,initial,repeat-x);
				opacity: .1;
				filter: blur(1px);

				animation-duration: 50s;
			}
		}

		.marquee-clouds-3-outer{
			top: 50vh;
			height: 100vh;
			.marquee-clouds-3-inner{
				@include bg-image('clouds-3.svg',100vw 300px,inherit,initial,repeat-x);
				opacity: .08;
				filter: blur(2px);

				animation-duration: 80s;
			}
		}

		.trees,.bldg-1,.bldg-2,.bldg-3,.bldg-4,.bldg-5{
			position: absolute;
			width: 100%;
			height: 500px;
			bottom: -61%;
			@include bg-image('trees.png',100% auto,inherit,center bottom);
		}

		.bldg-1{
			height: 80vh;
			bottom: -40%;
			@include bg-image('bldg-1.png',100% auto,inherit,center bottom);
		}

		.bldg-2{
			height: 200vh;
			bottom: -42%;
			@include bg-image('bldg-2.png',100% auto,inherit,center bottom);
		}

		.bldg-3{
			height: 100vh;
			bottom: -30%;
			@include bg-image('bldg-3.png',100% auto,inherit,center bottom);
		}

		.bldg-4{
			height: 100vh;
			bottom: -35%;
			@include bg-image('bldg-4.png',100% auto,inherit,center bottom);
		} 

		.bldg-5{
			margin-left: -5%;
			width: 110%;
			height: 150vh;
			bottom: -160%;
			filter: blur(3px);
			@include bg-image('bldg-5.svg',100% auto,inherit,center bottom);
		} 
	}

	section.who,
	section.approach,
	section.purpose{
		height: 400vh;
		.content{
			@include flex("row-center-top",nowrap,.5rem);
			margin-top: 250vh;
			div{
				margin: 10rem auto 0;
				width: 50%;
				text-align: center;
				background-color: rgb(255 255 255 / .5);
				border-radius: 1rem;
				a{
					display: none;
				}
			}
		}
		.three{
			display: none;
		}
	}

	section.who{
		height: 800vh;
		background-color: $c3-dgray;
		.content{
			margin-top: 50vh;
			@include flex("row-center-top",nowrap,.5rem);
			div{
				width: 100%;
				background-color: transparent;
				p{
					color: #fff;
					span{
						text-transform: uppercase;
						font-weight: 700;
						color: $c3-green;
					}
				}
				ul{
					margin-top: 1rem;
					padding: 1rem;
					@include flex("row-center-top",nowrap,.5rem);
					width: calc(100% - 2rem);
					li{
						display: block;
						width: calc((100% / 5) - 1rem);
						font-size: calc(.4rem + 12 * (100vw - 300px) / 1300);
						font-weight: 300;
						text-transform: uppercase;
						text-align: center;
						color: #fff;
						&:before{
							content: '';
							display: block;
							margin: 0 auto 1rem;
							width: calc((1200px / 5) - 4rem);
							height: calc((1200px / 5) - 4rem);
							border-radius: 100vmax;
						}
						@for $i from 1 through 5 {
							$img: 'who/who-'+$i+'.png';
							&:nth-of-type(#{$i}):before{
								@include bg-image(#{$img},50%,inherit,center,no-repeat,rgb(51 62 72 / 0.5));
							}
						}
					}
				}
			}
		}
	}

	section.teams{
		height: 200vh;
		background-color: $c3-gray;
		.marquee-title{
			margin-top: 65vh;
			padding-top: 10vh;
			width: 100%;
			height: 3.5rem;
			font-size: 3rem;
			overflow: hidden;
			color: #fff;
			.marquee-txt{
				position: absolute;
				border-style: solid none;
				border-width: 1px;
				border-color: $c3-green;
				width: 5200px;
				height: 3.5rem;
				--offset: 650px;
		    --move-initial: calc(-25% + var(--offset));
		    --move-final: calc(-50% + var(--offset));

				animation-name: marquee;
				animation-duration: 15s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				span{
					position: absolute;
					display: inline-block;
					width: 2600px;
					font-weight: 300;
					text-align: center;
					text-transform: uppercase;
					white-space: nowrap;
				}
				span:nth-of-type(2){
					left: 2600px;
				}
			}
		}
		.content{
			@include flex("column-center-left",nowrap,.0);
			margin-top: 0;
			overflow: hidden;
			max-width: 100%;
			height: calc(100vh - 5rem);
			.col-container{
				@include flex("row-center-center",wrap,1rem);
				width: 100%;
				.col:not(.team-pics){
					width: calc(50% - 2rem);
					max-width: 720px;
					text-align: right;
					p{
						color: #fff;
					}
				}
				.view-all{
					margin-top: 2rem;
					padding: 1rem;
					width: calc(100vw - 2rem);
					max-width: 1200px;
					// height: 2rem;
					border-top: solid 1px $c3-gray;
					text-align: center;
					a{
						display: inline-block;
						margin-top: 1rem;
						padding: 1rem 2rem;
						font-weight: 400;
						font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
						text-transform: none;
						border-radius: 100vmax 100vmax 100vmax 0;
						background-color: rgb(51 62 72 / .5);
						color: #fff;
						background-color: $c3-green;

						transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
						&:hover{
							color: $c3-green;
							background-color: #fff;
						}
					}
				}
			}
		}
	}

	section.branches{
		height: 200vh;
		background-color: $c3-gray;
		.content{
			@include flex("row-center-center",nowrap,.5rem);
			max-width: 100%;
			margin-top: 50vh;
			div{
				margin:0 auto;
				width: 100%;
				height: 50%;
				h3{
					margin: 0 auto;
					padding: 1rem;
					position: relative;
					text-align: center;
					z-index: 6;
					width: 50%;
					max-width: 1200px;
					color: #fff;
				}
				
				.bldg-3,.bldg-4{
					position: absolute;
					left: 0;
					height: 100vh;
					width: calc(100% + 2rem);
					max-width: calc(100% + 2rem);
					background-color: #fff;
				}
				.bldg-3{
					opacity: .3;
					bottom: 50vh;
					@include bg-image('bldg-3.png',100% auto,inherit,center bottom);
					z-index: 2;
				}

				.bldg-4{
					opacity: .1;
					bottom: 60vh;
					@include bg-image('bldg-4.png',100% auto,inherit,center bottom);
					z-index: 1;
				}
			}
		}
		.branch-link{
			// position: fixed;
			position: absolute;
			@include flex("row-center-top",nowrap,0);
			top: 50vh;
			left: 0;
			padding-top: 50vh;
			width: 100%;
			height:	50vh;
			// opacity: 0;
			overflow: hidden;
			isolation: isolate;
			z-index: 10;

			transition: opacity .5s cubic-bezier(1, 0.08, 0.35, 1);
			.content{
				display: none;
			}
			a{
				margin-top: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
				padding: 1rem 2rem;
				border-radius: 100vmax 100vmax 100vmax 0;
				background-color: rgb(51 62 72 / .5);
				backdrop-filter:blur(5px);
				color: #fff;

				transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
				&:hover{
					color: $c3-gray;
					background-color: $c3-green;
				}
			}
			&.who a{
				margin-top: 30vh;
			}
			// branches col
			.trees{
				position: absolute;
				left: 0;
				top: 0;
				height: 100vh;
				width: 100%;
				max-width: 100%;
				opacity: 1;
				@include bg-image('branches/trees.svg',100% auto,inherit,center bottom,no-repeat,rgb(51 62 72 / 0));
				backdrop-filter:blur(0);
				z-index: 0;

				transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
			}

			.col-container{
				@include flex("row-center-center",nowrap,1rem);
				margin-top: 0;
				width: 100%;
				height: 50vh;
				z-index: 1;
				.col{
					@include flex("row-center-center",nowrap,1rem);
					margin: 0;
					padding: 1rem;
					width: calc(100% / 3);
					a{
						position: relative;
						padding: 1rem 3rem;
						font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
						color: #fff;
						background-color: $c3-green;
						border-radius: 100vmax 100vmax 100vmax 0;
						z-index: 1;
						&:hover{
							color: $c3-green;
							background-color: #fff;
							+ img{
								bottom: 1vh;
								opacity: 1;
							}
						}
					}
					img{
						position: absolute;
						bottom:0;
						left: 0;
						width: 100%;
						object-fit: cover;
						z-index: -1;
						opacity: .2;

						transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
					}
				}
			}

			&:has(.col-container a:hover) .trees{
				backdrop-filter:blur(5px);
				background-color: rgb(51 62 72 / .5);
			} 
		}
	}

	section.clients{
		padding-top: 10vh;
		height: 200vh;
		background-color: $c3-gray;
		.content{
			@include flex("row-top-center",nowrap,.5rem);
			margin-top: 60vh;
			.text-content{
				@include flex("column-center-center",nowrap,.5rem);
				margin: 0 auto 0;
				width: 50%;
				height: calc(50% - 5rem);
				max-width: 1200px;
				h3{
					width: 100%;
					text-align: center;
				}
				p{
					width: 100%;
					text-align: center;
					color: #fff;
				}
			}
			.slider{
				position: absolute;
				top:0;
				left: 0;
				height: 100%;
				width: 100%;
				.slider-inner{
					position: absolute;
					// display: flex;
					display: block;
					top: 50%;
					margin-left: 100px;
					width: calc(100% - 200px);
					height: 10rem;
					overflow: hidden;

					.slick-list{
						display: block;
						overflow: hidden;
						width: 100%;
					}
					
					.slick-slide,
					.item-overlay{
						position: relative;
						display: inline-block;
						width: 10rem;
						height: 10rem;
						overflow:hidden;
						border-radius: 100vmax;
						background-color: #fff;
						.img{
							width: 100%;
							height: 100%;
							background-size: 100%;
							background-position: center center;
							background-repeat: no-repeat;
							filter:grayscale(1);

							transition: all .5s ease-out;
							cursor: pointer;
							&:hover{
								overflow: hidden;
								filter:grayscale(0);
							}
						}
					}
					.slick-slide{
						margin: 0 .5rem;
					}
				}
			}
			.arrow-left,.arrow-right{
				position: absolute;
				top: 105vh;
				left: 0;
				height: 10rem;
				width: 100px;
				z-index: 99;
				background-image: url('../img/arrow.svg');
				background-size: 50px;
				background-position: center;
				background-repeat: no-repeat;

				transition: background-color .3s, opacity .3s cubic-bezier(1, 0.08, 0.35, 1);
				&:hover{
					background-color: rgb(0 0 0 / .5);
				}
				&.show{
					opacity: 1;
				}
			}			
			.arrow-right{
				left: auto;
				right: 0;
				transform: scaleX(-1);
			}
		}
	}

	section.works{
		height: 200vh;
		background-color: $c3-gray;
		overflow: hidden;
		.content{
			transform: translate3d(0px, 0px, 0px);
			margin-top: 50vh;
			max-width: 100%;
			@include flex("row-center-top",wrap,0);
			.text-content{
				@include flex("row-center-center",wrap,0);
				width: 50%;
				max-width: 1200px;
				height: calc(50% - 60px);
				p{
					margin: 0 auto;
					color: #fff;
					text-align: center;
				}
			}
			.slider{
				position: absolute;
				top:0;
				left: 0;
				height: 100%;
				width: 100%;
				.slider-inner{
					position: absolute; 
					// display: flex;
					margin-left: 100px;
					top: calc(50% - 60px);
					width: calc(100% - 200px);
					height: 55%;
					overflow: hidden;

					.slick-list{
						display: block;
						overflow: hidden;
						width: 100%;
					}

					.slick-slide,
					.item-overlay{
						position: relative;
						display: inline-block;
						width: 800px;
						// height: 100%;
						height: 25rem;
						overflow:hidden;
						.img{
							position: absolute;
							left:-100px;
							width: 1000px;
							height: 100%;
							background-size: cover;
							background-position: top center;
							filter:grayscale(1);

							transition: all .5s ease-out;
							&:hover{
								left: 0;
								width: 100%;
								filter:grayscale(0);
							}
						}
						.bubble{
							position: absolute;
							display: block;
							width: 100px;
							padding: 1rem 1.5rem;
							color: #fff;
							text-align: center;
							border-radius: 100vmax 100vmax 100vmax 0;
							background-color: rgb(51 62 72 / .8);
							backdrop-filter:blur(5px);
							opacity: 0;

							transition: opacity .3s cubic-bezier(1, 0.08, 0.35, 1);
							em{
								display: block;
								font-size: .65rem;
								font-style: normal;
								text-transform: uppercase;
							}
							&.show{
								opacity: 1;
							}
						}
					}

					.slick-slide{
						margin: 0 .5rem;
					}
				}
			}
			.arrow-left,.arrow-right{
				position: absolute;
				top: calc(50% - 60px);
				left: 0;
				height: 55%;
				width: 100px;
				z-index: 99;
				background-image: url('../img/arrow.svg');
				background-size: 50px;
				background-position: center;
				background-repeat: no-repeat;

				transition: background-color .3s, opacity .3s cubic-bezier(1, 0.08, 0.35, 1);
				&:hover{
					background-color: rgb(0 0 0 / .5);
				}
				&.show{
					opacity: 1;
				}
			}			
			.arrow-right{
				left: auto;
				right: 0;
				transform: scaleX(-1);
			}
		}
	}

	section.contact{
		height: 150vh;
		.map{
			margin: 1rem auto;
			width: 100%;
			height: 35vh;
			background-color: $c3-dgray;
			overflow: hidden;
			.mapouter,
			.gmap_canvas,
			iframe{
				width: 100%;
				height: 100%;
			}
		}
		.content{
			@include flex("column-center-center",nowrap,.5rem);
			margin-top: 50vh;
			width: 100%;	
			max-width: 1200px;
			p{
				margin: 0 auto;
				width: 680px;
				text-transform: none;
				text-align: center;
				color: #fff;
			}
			.btn{
				display: block;
				margin: 0 auto;
			}
		}
		&:before{
			content: '';
			position: absolute;
			display: block;
			inset:0;
			@include bg-image('none',100%,inherit,initial,no-repeat,none,true,$c3-gray,#1a1a1a,#1a1a1a);
			z-index: -1;
		}
	}
}

.show .cross,
.inner .cross{
	&:nth-of-type(1),
	&:nth-of-type(2){
		top: 5rem;
	}
}

.bottom .cross,
.inner .cross{
	&:nth-of-type(3),
	&:nth-of-type(4){
		bottom:5rem;
	}
}
 