.btn {
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  color: $c3-gray;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  border: none;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: $c3-green;

  cursor: pointer;
  transition: all .5s cubic-bezier(1, 0.08, 0.35, 1); ;
  &:hover {
    color: #fff;
    background-color: $c3-gray;
  }
}

@media (max-width: 640px){
  .btn {
    margin: 1rem 0;
  }
}