footer{
	margin: 0;
	padding: 0 1rem;
	height: auto;
	background-color: #1a1a1a;
	.map{
		margin-left: -1rem;
		width: calc(100% + 2rem);
		height: 50vh;
		background-color: $c3-dgray;
		overflow: hidden;
		.map-container{
			overflow:hidden;
			resize:none;
			width:100%;
			max-width:100%;
			height:100%;
			#embed-ded-map-canvas{
				height:100%;
				width:100%;
				max-width:100%;
			}
		}
		.mapouter,
		.gmap_canvas,
		iframe{
			height:100%;
			width:100%;
			border:0;
		}
	}
	.content{
		@include flex("row-center-center",nowrap,0);
		padding-top:1rem;
		width: 100%;
		.side-col{
			padding: 1rem;
			width: calc(30% - 2rem);
			&:nth-of-type(1){
				@include flex("row-center-center",nowrap,0);
				img{
					width: 10rem;
				}
			}
			&:nth-of-type(3){
				@include flex("row-center-left",nowrap,0);
				padding-left: 3rem;
				border-left: solid 1px #fff;
				ul{
					margin:0;
					padding: 0;
					li{
						margin:1rem 0;
						padding: 0;
						list-style: none;
						a{
							padding: .5rem;
							font-size: 1rem;
							font-weight: 300;
							color: #fff;
							background-color: #1a1a1a;
							border-radius: 100vmax 100vmax 100vmax 0;

							transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
							&:hover{
								color: $c3-gray;
								background-color: $c3-green;
							}
						}
					}
				}
				.return{
					display: none;
				}
			}
		}
		.mid-col{
			@include flex("row-top-left",nowrap,0);
			padding: 1rem;
			width: calc(70% - 2rem);
			.col{
				padding: 1rem;
				width: calc((100% / 3) - 2rem);
				p{
					color: #fff;
					&.title{
						padding: .5rem 0;
						font-size: 1.2rem;
						border-bottom: solid 1px #fff;
						span{
							color: $c3-green;
						}
					}
					&.address{
						font-size: .8rem;
					}
				}
				&.twin{
					.address{
						padding: 0 1rem;
					}
				}
				&.twin:nth-of-type(1){
					padding-right: 0;
					
				}
				&.twin:nth-of-type(2){
					padding-left: 0;
					.title strong{
						opacity: 0;
					}
				}
			}
		}
	}
	.bot-content{
		@include flex("row-center-wide",nowrap,0);
		margin-top: 2rem;
		padding: 1rem;
		border-top: solid 1px #fff;
		div{
			@include flex("row-center-center",nowrap,0);
			a{
				color: #fff;
				font-size: .8rem;	
			}
			span{
				display: inline-block;
				margin: 0 1rem;
				border-left: solid 1px #555;
				height: 2rem;
			}
		}
		p{
			margin: 0;
			font-size: .8rem;
			color: #fff;
		}
	}
	
}