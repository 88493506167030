$media-string: "only screen and ";
$smallmobile: $media-string+"(max-width: 320px)";
$tablet: $media-string+"(max-width: 768px)";
$large-tablet: $media-string+"(max-width: 1024px)";
$large-desktop: $media-string+"(min-width: 1200px)";
$larger-desktop: $media-string+"(min-width: 1400px)";
$largest-desktop: $media-string+"(min-width: 1860px)";
$l4k-desktop: $media-string+"(min-width: 3000px)";

$media-tablet: 640px;
$media-largeTablet: 768px;
$media-desktop: 1024px;
$media-largeDesktop: 1440px;

@media (min-width: 1600px) {
	main.inner{
		section.contact{
			.content{
				p{
					width: 1200px;
				}
			}
		}
	}
}

@media (max-width: $media-desktop) {
	:root{
		--base-font-size: 14px;
	}

}

@media (max-width: $media-largeTablet) {
	:root{
		--base-font-size: 16px;
	}
	body.inner{
		main.inner{
			section.banner{
				&:before{
					opacity: .8;
					mix-blend-mode: multiply;
				}&:before,&:after{
					width: 100%;
				}
				&.about-us:after{
					@include bg-image('./about-us/about-us.png',cover,initial,center);
				}
				&.about-us-process:after{
					@include bg-image('./about-us/process.jpg',cover,initial,center);
				}
				&.about-us-team:after{
					@include bg-image('./about-us/team.jpg',cover,initial,center);
				}
				&.services:after{
					@include bg-image('./services/services.jpg',cover,initial,center);
				}
				&.singapore:after{
					@include bg-image('./branches/singapore-banner.jpg',cover,initial,center);
				}
				&.shanghai:after{
					@include bg-image('./branches/shanghai-banner.jpg',cover,initial,center);
				}
				&.manila:after{
					@include bg-image('./branches/manila-banner.jpg',cover,initial,center);
				}
				&.work:after{
					@include bg-image('./works.jpg',cover,initial,center);
				}
				&.careers:after{
					@include bg-image('./careers.jpg',cover,initial,center);
				}
				.content{
					@include flex("row-center-center",nowrap,0);
					.col-left{
						width: calc(100% - 4rem);
						>div{
							max-width: calc(100% - 2rem);
							h1{
								margin: 0;
								width: 100%;
								text-align: center;
							}
							p{
								text-align: center;
							}
							a{
								display: block;
								text-align: center;
							}
						}
					}
				}
			}

			section.container{
				padding: 3rem 0;
				.content{
					padding: 2rem 0;
					@include flex("column-top-center",nowrap,0);
					&.top-align{
						@include flex("column-top-center",nowrap,0);
					}
					.col{
						padding: 0 2rem;
						width: calc(100% - 4rem);
						&.left-minor,&.right-minor,
						&.left-major,&.right-major{
							width: calc(100% - 4rem);
							&.no-padding{
								width: 100%;
								img.our-process-cta,
								img.our-team-cta{
									width: 5rem;
								}
							}
						}
						h3{
							text-align: center;
						}
						p{
							width: calc(100% - 2rem);
							&[data-timeline]:before{
								position: relative;
								left: 0;
								top: 0;
								width: 100%;
								height: auto;
								text-align: center;
								transform: none;
								font-size: calc(5rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
							}
						}
						img{
							width: calc(100% - 2rem);
						}
						.serv{
							@include flex("row-center-center",wrap,0);
							.img-container{
								width: 5rem;
								img{
									width: 100%;
								}
							}
							.text-container{
								width: calc(100% - 7rem);
							}
						}
						p{
							font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
							&.trans{
									text-align: center;
							}
						}
						>div{
							width: 100%;
						}
					}
					&.mid-line:before,
					&.mid-line-dash:before{
						display: none;
					}
					&.timeline{
						@include flex("column-top-center",nowrap,0);
						height: auto;
						.col.left-minor{
							margin-bottom: 2rem;
							width: calc(100vw - 4rem);
							height: calc(100vw - 4rem);
							&.merlion .icon,&.oriental-pearl .icon,&.rizal .icon,&.kuala .icon,&.cryto .icon,&.iot .icon{
								background-size: 100%;
								background-attachment: inherit;
							}
						}
						p{

						}
					}
					img{
						&.modal{
							margin-bottom: 1rem;
							width: calc((100% / 2) - 1rem);
						}
					}
				}
				p{
					margin: 0;
					padding: 0 2rem;
				}
				&.services{
					padding: 2rem 0;
					.content{
						padding: 0 1rem;
					}
				}

				&.green{
					.content{
						@include flex("column-center-left",wrap,0);
						.col{
							@include flex("column-center-left",wrap,0);
						}
					}
				}

				&.lgray-1{

				}
				&.lgray-2{
					padding: 0;
					.content{
						padding: 0;
						@include flex("column-top-center",nowrap,0);
						height: auto;
						.col{
							a{

							}
							&.left-major{
								padding: 1rem 2rem;
							}
						}
					}
				}
				&.works-grid{
					.grid-col{
						flex-basis: 50%;
					}
				}
			}

			section.contact{
				h1{
					margin-top: 5rem;
				}
				.content{
					>div{
						padding-top: 7rem;
						margin-top: 0;
					}
					.form-content{
						padding-top: 2rem;
					}
				}
			}

			section.contact.cta{
				padding-top: 0;
				.content{
					margin-bottom: 0;
					div{
						padding: 2rem 0 2rem;
						p{
							width: 80%;
						}
						a{
							width: 100%;
							max-width: 50vw;
						}
					}
				}
				.map{
					margin-top: 0;
				}
			}

			.btn{
				width: 100%;
				max-width: 50vw;
				margin-top: 1rem;
				margin-bottom: 1rem;
				font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
			}
		}
		footer{
			.content{
				.side-col{
					&:nth-of-type(1){
						display: block;
						img{
							width: 100%;
						}
					}
				}
				.mid-col{
					margin-top: 0;
					.col:nth-of-type(1){
						margin-top: 0;
					}
				}
			}
		}
	}
}