@mixin trans($property: all, $dur:1s, $delay:0){
	transition-property: $property;
	transition-duration: $dur;
	transition-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
	transition-delay: $delay;
}


@keyframes scroll-indicator-line{
	from{
		bottom: 1rem;
		height: 2.3rem;
	}to{
		bottom: -9rem;
		height: 12rem;
	}
}

@keyframes scroll-indicator-arrow{
	from{
		bottom: -2rem;
	}to{
		bottom: -10rem;
	}
}

@keyframes marquee{
	0%{
		 transform: translateX(0); 
	}
	100%{
		 transform: translateX(-50%);
	}
}