h1{
	margin-bottom: 1rem;
	font-size: calc(3rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 700;
	text-transform: uppercase;
	span{
		display: none;
	}
	&.text-left{
		text-align: left;
	}
}

h2{
	margin-bottom: 1rem;
	font-size: calc(2.6rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 400;
	text-transform: uppercase;
	color: $c3-gray;
	span{
		color: $c3-green;
	}
}

h3{
	margin-bottom: 1rem;
	font-size: calc(1.5rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
	text-transform: uppercase;
	color: $c3-gray;
	span{
		color: $c3-green;
	}
}

h4{
	font-size: calc(1.2rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));;
}

h5{

}

h6{

}

p{
	margin-bottom: $gap-1;

	font-family: inherit;
	font-size: calc(.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
	line-height: 1.5;

	color: $c3-gray;

	&.white{
		color: $white;
	}
	&.text-left{
		text-align: left;
	}

	&.text-center{
		text-align: center;
	}

	&.text-right{
		text-align: right;
	}
}
@media (max-width: 640px) {
	p{
		font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	}
}

a{
	font-size: calc(.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	text-decoration: none;
	color: $c3-gray;
}
@media (max-width: 640px) {
	a{
		font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
	}
}

i,em{
	color: $c3-green;
}

em{
	font-weight: 400;
}

ul,ol{
	margin: 1rem 0;
	padding: 1rem 1rem 1rem 2rem;
	li{
		margin-bottom: 1rem;
		line-height: 1.5;
		font-weight: 300;
	}
}