// slider interactions CSS
// works slider
// for images displayed see main.scss under section.works
.slider-control{
	position: fixed;
	// display: none;
	top: 100%;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 2;
	.content{
		display: none;
	}
	.slider-overlay{
		position: absolute;
		top:0;
		left: 0;
		height: 100%;
		width: 4200px;
		z-index: 10;
		.slider-inner-overlay{
			position: absolute;
			padding: 0 10rem;
			display: flex;
			top: calc(50% - 60px);
			width: 100%;
			height: 55%;
			justify-content: space-around;
			.item{
				.img{
					transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
					&.over{
						left: 0;
						width: 100%;
						filter:grayscale(0);
					}
				}
			}
			.item-overlay{
				position: relative;
				width: 800px;
				height: 100%;
				cursor: pointer;
				.bubble{
					position: absolute;
					display: block;
					width: 100px;
					padding: 1rem 1.5rem;
					color: #fff;
					text-align: center;
					border-radius: 100vmax 100vmax 100vmax 0;
					background-color: rgb(51 62 72 / .8);
					backdrop-filter:blur(5px);
					opacity: 0;

					transition: opacity .3s cubic-bezier(1, 0.08, 0.35, 1);
					em{
						display: block;
						font-size: .65rem;
						font-style: normal;
						text-transform: uppercase;
					}
					&.show{
						opacity: 1;
					}
				}
			}
		}
	}
	.arrow-left,.arrow-right{
		position: absolute;
		top: calc(50% - 60px);
		left: 0;
		height: 55%;
		width: 100px;
		z-index: 99;
		background-image: url('../img/arrow.svg');
		background-size: 50px;
		background-position: center;
		background-repeat: no-repeat;
		opacity: 0;

		transition: background-color .3s, opacity .3s cubic-bezier(1, 0.08, 0.35, 1);
		&:hover{
			background-color: rgb(0 0 0 / .5);
		}
		&.show{
			opacity: 1;
		}
	}			
	.arrow-right{
		left: auto;
		right: 0;
		transform: scaleX(-1);
	}
}

// client slider
.slider-control.clients{
	top:100%;
	.slider-overlay{
		position: absolute;
		width: calc(12rem * 15);
		.slider-inner-overlay{
			top:50%;
			height: 10rem;
			.item-overlay{
				width: 10rem;
				height: 10rem;
				border-radius: 100vmax;
				overflow: hidden;
			}
		}
	}
	.arrow-left,.arrow-right{
		top: calc(50%);
		height: 10rem;
	}
}

@media (max-width: 768px) {
	.slider-control{
		@include bg-image('grid-2.svg',50px 50px,inherit,initial,repeat,$c3-gray);
		.content{
			padding: 0 1rem;
			@include flex("column-bottom-center",wrap,1rem);
			width: calc(100% - 2rem);
			height: 50vh;
			text-align: center;
			p{
				width: 80%;
				color: #fff;
			}
		}
		.slider-overlay{
			width: 500vw;
			.slider-inner-overlay{
				padding: 0;
				top: calc(60% - 50px);
				height: 30%;
				justify-content: space-around;
				.item-overlay{
					@include flex("row-center-left",wrap,1rem);
					width:80vw;
					height: 100%;
					.bubble{
						display: none;
					}
					img{
						width: 100%;
					}
				}
			}
		}
		.arrow-left,.arrow-right{
			width: 50px;
			background-size: 20px;
			&:hover{
				background-color: transparent;
			}
		}
	}
}

// Team profile pics
.team-pics{
	// position: fixed;
	// padding: 5vw 2rem 0;
	// top:0;
	// right: 0;
	width: calc(50% - 4rem);
	max-width: 50%;
	height: calc(100% - 2.5rem);
	// opacity: 0;
	z-index: 3;

	transition: opacity .5s cubic-bezier(1, 0.08, 0.35, 1);
	.text-content{
		display: none;
	}
	.team-content{
		position: relative;
		height: calc(50% + 6.5rem);
		width: 100%;

		@include flex("row-bottom-center",wrap,2rem);
		.pic{
			position: relative;
			// width: 1vw;
			// height: 1vw;
			margin: 0 .25rem;
			width: calc((40vw / 3) - 1rem);
			height: calc((40vw / 3) - 1rem);
			border-radius: 0 0 100vmax 100vmax;
			overflow: hidden;
			cursor: pointer;

			.profile-norm{
				position: absolute;
				bottom: -3vw;
				left: 0;
				width: 100%;
				opacity: 1;

				transition: bottom .5s .4s  cubic-bezier(1, 0.08, 0.35, 1) , opacity .5s .2s cubic-bezier(1, 0.08, 0.35, 1) ;
			}
			.profile-pop{
				position: absolute;
				bottom: -3vw;
				left: 0;
				width: 100%;
				opacity: 0;

				transition: bottom .5s .4s  cubic-bezier(1, 0.08, 0.35, 1) ,  opacity .5s .2s cubic-bezier(1, 0.08, 0.35, 1) ;
			}
			.bubble{
				position: absolute;
				bottom:-150%;
				left: 0;
				width: calc(100% - 2rem);
				padding: 1rem 1rem 2rem;
				border-radius: 100vmax 100vmax 100vmax 0;
				background-color: $c3-green;
				backdrop-filter:blur(5px);
				transform: rotate(90deg);
				opacity: 0;
				z-index: -2;

				transition: bottom .5s .5s, opacity .5s .7s, transform .4s .6s cubic-bezier(1, 0.08, 0.35, 1) ;
				p{
					margin: 0;
					padding: 0;
					// font-size: .8rem;
					color: #fff;
					text-align: center;
					&.name{
						// font-size: 1rem;
						text-transform: uppercase;
						font-weight: 700;
						color: $c3-gray;
					}
					&.position{
						font-weight: 700;
					}
				}
			}
			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				// width: 0;
				// height: 0;
				width: calc(((40vw / 3) - 1rem) - 2px);
				height: calc(((40vw / 3) - 1rem) - 2px);
				border: solid 1px #fff;
				border-radius: 100vmax;
				background-color: #fff;
				z-index: -3;
			}
			&:hover{
				overflow: hidden;
				margin-top: calc(((40vw / 3) - 1rem) * -1) ;
				height: calc(((40vw / 3) - 1rem) * 2) ;
				.profile-pop{
					opacity: 1;
					bottom: -1rem;
				}
				.profile-norm{
					opacity: 0;
					bottom: -1rem;
				}
				.bubble{
					bottom:calc((40vw / 3) - 1rem);
					transform: rotate(0deg);
					opacity: 1;
				}
			}
		}
		.pic:nth-of-type(1), .pic:nth-of-type(1):before{
			transition: all 1s cubic-bezier(1, 0.08, 0.35, 1) ;
		}
		.pic:nth-of-type(2), .pic:nth-of-type(2):before{
			transition: all .8s .2s cubic-bezier(1, 0.08, 0.35, 1) ;
		}
		.pic:nth-of-type(3), .pic:nth-of-type(3):before{
			transition: all .6s .4s cubic-bezier(1, 0.08, 0.35, 1) ;
		}
	}
	// &.show{
	// 	opacity: 1;
	// 	.pic{
	// 		width: calc((40vw / 3) - 1rem);
	// 		height: calc((40vw / 3) - 1rem);
	// 		&:before{
	// 			width: calc(((40vw / 3) - 1rem) - 2px);
	// 			height: calc(((40vw / 3) - 1rem) - 2px);
	// 		}
	// 	}
	// }
}

@media (max-width: 768px) {
	.team-pics{
		width: calc(100% - 4rem);
		max-width: calc(100% - 4rem);
		.team-content{
			margin-top: 5rem;
			height: 12rem;
			// height: calc(50% + 10.5rem);
			z-index: 1;
			.pic{
				.profile-norm{
					bottom: -5vh;
				}
				.bubble{
					width: calc(100% - 1rem);
					padding: .5rem .5rem 1rem;
					p{
						font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
						&.name{
							margin: 0;
							font-weight: 700;
						}
						&.position{
							margin: 0;
							font-weight: 300;
						}
					}
				}
				width: calc((100vw / 3) - 2rem);
				height: calc((100vw / 3) - 2rem);
				&:before{
					width: calc(((100vw / 3) - 2rem) - 2px);
					height: calc(((100vw / 3) - 2rem) - 2px);
				}
				&:hover{
					margin-top: calc(((100vw / 3) - 1rem) * -1) ;
					height: calc(((100vw / 3) - 1.5rem) * 2) ;
					
					.bubble{
						bottom:calc((100vw / 3) - 1.5rem);
						transform: rotate(0deg);
					}
				}
			}
		}
		.view-all{
			margin-left: 0;
			width: calc(100% - 2rem);
			a{
				text-align: center;
			}
		}
	}
}

@media (max-width: 640px) {
	.team-pics{
		.team-content{
			margin-top: -5rem;
		}
	}
}

// approch, purposes and branches link
.link{
	position: fixed;
	@include flex("row-center-top",nowrap,0);
	top: 5;
	left: 0;
	padding-top: 50vh;
	width: 100%;
	height:	50vh;
	opacity: 0;
	overflow: hidden;
	isolation: isolate;
	z-index: 10;

	transition: opacity .5s cubic-bezier(1, 0.08, 0.35, 1);
	.content{
		display: none;
	}
	a{
		margin-top: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
		padding: 1rem 2rem;
		border-radius: 100vmax 100vmax 100vmax 0;
		background-color: rgb(51 62 72 / .5);
		backdrop-filter:blur(5px);
		color: #fff;

		transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
		&:hover{
			color: $c3-gray;
			background-color: $c3-green;
		}
	}
	&.who a{
		margin-top: 30vh;
	}
	&.show{
		opacity: 1;
	}
}


@media (min-width: 769px) and (max-width: 1200px) {
	.link{
		// display: none;
		@include flex("column-center-center",nowrap,1rem);
		height: 100vh;
		padding-top:0;
		
		.content{
			display: block;
			padding: 0 2rem 1rem;
			width: calc(50% - 4rem);
			a{
				display: block;
				margin: 3rem auto 0;
				width: 70%;
				text-align: center;
			}
			p{
				color: $c3-gray;
				text-align: center;
				span{
					font-weight: 700;
					text-transform: uppercase;
					color: $c3-green;
				}
				&.dark{
					color: $c3-gray;
				}
			}
			h3{
				width: 100%;
				text-align: center;
				&.dark{
					color: #fff;
				}
			}
		}
		>a{
			display: none;
		}
		&.who{
			.content{
				width: calc(100% - 4rem);
				@include flex("column-center-center",nowrap,1rem);
				div{
					width: 100%;
					background-color: transparent;
					p{
						margin: 1rem auto;
						width: 50%;
						color: #fff;
						span{
							text-transform: uppercase;
							font-weight: 700;
							color: $c3-green;
						}
					}
					a{
						margin: 1rem auto 0;
					}
					ul{
						margin-top: 1rem;
						padding: 1rem;
						@include flex("row-center-top",nowrap,.5rem);
						width: calc(100% - 2rem);
						li{
							display: block;
							width: calc((100% / 5) - 1rem);
							font-size: calc(.4rem + 12 * (100vw - 300px) / 1300);
							font-weight: 300;
							text-transform: uppercase;
							text-align: center;
							color: #fff;
							&:before{
								content: '';
								display: block;
								margin: 0 auto 1rem;
								width: calc((1200px / 5) - 6rem);
								height: calc((1200px / 5) - 6rem);
								border-radius: 100vmax;
							}
							@for $i from 1 through 5 {
								$img: 'who/who-'+$i+'.png';
								&:nth-of-type(#{$i}):before{
									@include bg-image(#{$img},50%,inherit,center,no-repeat,rgb(51 62 72 / 0.5));
								}
							}
						}
					}
				}
			}
		}
		// &.purpose,
		// &.approach{
		// 	@include flex("column-center-center",nowrap,1rem);
		// 	.content{
		// 		margin-top: 10rem;
		// 	}
		// }
	}
}

// modal
.modal-container{
	position: fixed;
	display: none;
	inset:0;
	background-color: rgb(0 0 0 / .8);
	backdrop-filter:blur(5px);
	z-index: 999;
	.modal-content{
		position: relative;
		width: 100%;
		height: 100%;
		@include flex("row-center-center",nowrap,1rem);
		.close{
			position: absolute;
			top: 1rem;
			right: 1rem;
			width: 3rem;
			height: 3rem;
			background-color: $c3-green;
			cursor: pointer;

			transition: background-color .5s cubic-bezier(1, 0.08, 0.35, 1);
			&:before,&:after{
				content: '';
				position: absolute;
				display: block;
				top:.25rem;
				left: 50%;
				width: 2px;
				height: 2.5rem;
				background-color: #fff;
				transform: rotate(45deg);
			}
			&:after{
				transform: rotate(-45deg);
			}
			&:hover{
				background-color: $c3-gray;
			}
		}
		img{
			width: 50vw;
		}
	}
}

@media (max-width: 768px) {
	.modal-container{
		.modal-content{
			img{
				width: 100%;
			}
		}
	}
}