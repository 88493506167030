aside{
	position: fixed;
	@include flex("row-center-center",nowrap,0); 
	top: 0;
	left: 0;
	width: 1rem;
	height: 100vh;
	z-index: 20;

	.scroll-indicator-container{
		position: relative;
		width: 100%;
		ul{
			position: relative;
			margin: 0;
			padding: 0;
			li{
				position: relative;
				margin: 0;
				padding: 0; 
				line-height: 0;
				list-style: none;
				text-align: center;
				a{
					position: relative;
					display: inline-block;
					padding: 1rem 0;
					margin: 0;
					font-size: .7rem;
					line-height: 1;
					text-transform: uppercase;

					background-color: rgb(100 100 100 / 0);

					transition: background-color .5s cubic-bezier(1, 0.08, 0.35, 1);
					span{
						position: absolute;
						top:.75rem;
						left: -10rem;
						display: block;
						margin-left: 1rem;
						padding: .25rem 1rem;
						width: 6rem;
						text-align: center;
						color: $c3-green;
						border-radius: 100vmax 100vmax 100vmax 0;
						background-color: rgb(51 62 72 / .8);
						backdrop-filter:blur(5px);
						opacity: 0;

						transition: all .3s cubic-bezier(1, 0.08, 0.35, 1);
					}
					&:before{
						content: '';
						position: relative;
						display: inline-block;
						width: .5rem;
						height: .5rem;
						border: solid 1px #ccc;
						border-radius: 100vmax;

						transition: background-color .3s, border 1s cubic-bezier(1, 0.08, 0.35, 1);
					}
					&.current:before{
						background-color: $c3-green;
					}
					&:hover{
						&:not(.current):before{
							background-color: rgb(100 100 100 / .3);
						}
						span{
							opacity: 1;
							left: 0;
						}
					}
				}
			}
		}
		&.dark{
			ul li a{
				span{
					color: #fff;
					background-color: rgb(150 201 64 / .8);
				}
				&:before{
					border: solid 1px #555;
				}
			}
		}
	}
}