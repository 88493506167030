nav{
	position: fixed;
	display: none;
	top:0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgb(0 0 0 /.8);
	backdrop-filter:blur(10px);
	z-index: 10;
	ul.outer-list{
		position: absolute;
		@include flex("column-center-right",nowrap,0); 
		top:0;
		right: -30rem;
		margin: 0;
		padding: 1rem;
		width: 25rem;
		height: 100%;
		background-color: $c3-gray;

		transition: right .5s cubic-bezier(1, 0.08, 0.35, 1);
		>li{
			margin: 0 8rem 1.5rem 0;
			padding: 0;
			list-style: none;
			text-align: right;
			a{
				position: relative;
				padding: .5rem;
				color: $c3-green;
				font-size: 1.8rem;
				font-weight: 700;
				text-transform: uppercase;
				border-right: solid 0 #fff;
				transition: border .3s ease-out;
				isolation: isolate;
				overflow: hidden;
				&:before{
					content: '';
					display: block;
					position: absolute;
					top:0;
					right: 0;
					width: 0;
					height: 100%;
					background-image: linear-gradient(to right, rgb(255 255 255 / 0) , rgb(255 255 255 / .3));
					z-index: -2;
				}
				&:after{
					content:attr(rel) "";
					display: block;
					position: absolute;
					padding-top: .8rem;
					padding-right: 0;
					top:0;
					right:0;
					color: $c3-gray;
					z-index: -1;
					font-size: inherit;
					font-weight: inherit;
					filter: blur(2px);
				}
				&:before,&:after{
					transition: all .3s cubic-bezier(1, 0.08, 0.35, 1);;
				}
				&:hover{
					border-right: solid 5px #fff;
					&:before{
						width: 100%;
					}
					&:after{
						padding-right: .8rem;
					}
				}
			}
		}
	}
	ul.inner-list{
		margin: 0;
		padding: 0;
		>li{
			margin: 0;
			padding: 0;
			list-style: none;
			a{
				position: relative;
				display: block;
				padding: .25rem .5rem;
				color: #fff;
				font-size: 1.2rem;
				font-weight: 700;
			}
		}
	}
	&.show{
		ul.outer-list{
			right: 0;
		}
	}
}

@media (max-width: 768px) {
	nav{
		ul.outer-list{
			width: calc(100% - 2rem);
			height: calc(100% - 2rem);
			>li{
				margin: 0 20% .5rem 0;
			}
		}
	}
}