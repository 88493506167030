/*  Material Design color palette (full) downloaded from
 *  http://htmlcolorcodes.com/color-charts/
 *
 *  Check out our powerful color picker
 *  http://htmlcolorcodes.com/color-picker/
 *
 *  Want to learn more about colors in SCSS?
 *  http://htmlcolorcodes.com/tutorials/
 *
 *  - - - - - - - - - - - - - - - - - - 
 *  Credits:
 *
 *  Original color palette by
 *  https://www.google.com/design/spec/style/color.html
 *
 *  - - - - - - - - - - - - - - - - - -
 *  Want more? Check our our other color palettes at
 *  http://htmlcolorcodes.com/color-charts/
 */
/*PROJECT UNIQUE COLORS*/
/*SCSS VARIABLES*/
/* Red */
/* Pink */
/* Purple */
/* Deep Purple */
/* Indigo */
/* Blue */
/* Light Blue */
/* Cyan */
/* Teal */
/* Green */
/* Light Green */
/* Lime */
/* Yellow */
/* Amber */
/* Orange */
/* Deep Orange */
/* Brown */
/* Grey */
/* Blue Grey */
/* White / Black */
/*set opacity of the specified color*/
:root {
  /* Red */
  --red: #f44336;
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;
  --red-a100: #ff8a80;
  --red-a200: #ff5252;
  --red-a400: #ff1744;
  --red-a700: #d50000;
  /* Pink */
  --pink: #e91e63;
  --pink-50: #fce4ec;
  --pink-100: #f8bbd0;
  --pink-200: #f48fb1;
  --pink-300: #f06292;
  --pink-400: #ec407a;
  --pink-500: #e91e63;
  --pink-600: #d81b60;
  --pink-700: #c2185b;
  --pink-800: #ad1457;
  --pink-900: #880e4f;
  --pink-a100: #ff80ab;
  --pink-a200: #ff4081;
  --pink-a400: #f50057;
  --pink-a700: #c51162;
  /* Purple */
  --purple: #9c27b0;
  --purple-50: #f3e5f5;
  --purple-100: #e1bee7;
  --purple-200: #ce93d8;
  --purple-300: #ba68c8;
  --purple-400: #ab47bc;
  --purple-500: #9c27b0;
  --purple-600: #8e24aa;
  --purple-700: #7b1fa2;
  --purple-800: #6a1b9a;
  --purple-900: #4a148c;
  --purple-a100: #ea80fc;
  --purple-a200: #e040fb;
  --purple-a400: #d500f9;
  --purple-a700: #aa00ff;
  /* Deep Purple */
  --deep-purple: #673ab7;
  --deep-purple-50: #ede7f6;
  --deep-purple-100: #d1c4e9;
  --deep-purple-200: #b39ddb;
  --deep-purple-300: #9575cd;
  --deep-purple-400: #7e57c2;
  --deep-purple-500: #673ab7;
  --deep-purple-600: #5e35b1;
  --deep-purple-700: #512da8;
  --deep-purple-800: #4527a0;
  --deep-purple-900: #311b92;
  --deep-purple-a100: #b388ff;
  --deep-purple-a200: #7c4dff;
  --deep-purple-a400: #651fff;
  --deep-purple-a700: #6200ea;
  /* Indigo */
  --indigo: #3f51b5;
  --indigo-50: #e8eaf6;
  --indigo-100: #c5cae9;
  --indigo-200: #9fa8da;
  --indigo-300: #7986cb;
  --indigo-400: #5c6bc0;
  --indigo-500: #3f51b5;
  --indigo-600: #3949ab;
  --indigo-700: #303f9f;
  --indigo-800: #283593;
  --indigo-900: #1a237e;
  --indigo-a100: #8c9eff;
  --indigo-a200: #536dfe;
  --indigo-a400: #3d5afe;
  --indigo-a700: #304ffe;
  /* Blue */
  --blue: #2196f3;
  --blue-50: #e3f2fd;
  --blue-100: #bbdefb;
  --blue-200: #90caf9;
  --blue-300: #64b5f6;
  --blue-400: #42a5f5;
  --blue-500: #2196f3;
  --blue-600: #1e88e5;
  --blue-700: #1976d2;
  --blue-800: #1565c0;
  --blue-900: #0d47a1;
  --blue-a100: #82b1ff;
  --blue-a200: #448aff;
  --blue-a400: #2979ff;
  --blue-a700: #2962ff;
  /* Light Blue */
  --light-blue: #03a9f4;
  --light-blue-50: #e1f5fe;
  --light-blue-100: #b3e5fc;
  --light-blue-200: #81d4fa;
  --light-blue-300: #4fc3f7;
  --light-blue-400: #29b6f6;
  --light-blue-500: #03a9f4;
  --light-blue-600: #039be5;
  --light-blue-700: #0288d1;
  --light-blue-800: #0277bd;
  --light-blue-900: #01579b;
  --light-blue-a100: #80d8ff;
  --light-blue-a200: #40c4ff;
  --light-blue-a400: #00b0ff;
  --light-blue-a700: #0091ea;
  /* Cyan */
  --cyan: #00bcd4;
  --cyan-50: #e0f7fa;
  --cyan-100: #b2ebf2;
  --cyan-200: #80deea;
  --cyan-300: #4dd0e1;
  --cyan-400: #26c6da;
  --cyan-500: #00bcd4;
  --cyan-600: #00acc1;
  --cyan-700: #0097a7;
  --cyan-800: #00838f;
  --cyan-900: #006064;
  --cyan-a100: #84ffff;
  --cyan-a200: #18ffff;
  --cyan-a400: #00e5ff;
  --cyan-a700: #00b8d4;
  /* Teal */
  --teal: #009688;
  --teal-50: #e0f2f1;
  --teal-100: #b2dfdb;
  --teal-200: #80cbc4;
  --teal-300: #4db6ac;
  --teal-400: #26a69a;
  --teal-500: #009688;
  --teal-600: #00897b;
  --teal-700: #00796b;
  --teal-800: #00695c;
  --teal-900: #004d40;
  --teal-a100: #a7ffeb;
  --teal-a200: #64ffda;
  --teal-a400: #1de9b6;
  --teal-a700: #00bfa5;
  /* Green */
  --green: #4caf50;
  --green-50: #e8f5e9;
  --green-100: #c8e6c9;
  --green-200: #a5d6a7;
  --green-300: #81c784;
  --green-400: #66bb6a;
  --green-500: #4caf50;
  --green-600: #43a047;
  --green-700: #388e3c;
  --green-800: #2e7d32;
  --green-900: #1b5e20;
  --green-a100: #b9f6ca;
  --green-a200: #69f0ae;
  --green-a400: #00e676;
  --green-a700: #00c853;
  /* Light Green */
  --light-green: #8bc34a;
  --light-green-50: #f1f8e9;
  --light-green-100: #dcedc8;
  --light-green-200: #c5e1a5;
  --light-green-300: #aed581;
  --light-green-400: #9ccc65;
  --light-green-500: #8bc34a;
  --light-green-600: #7cb342;
  --light-green-700: #689f38;
  --light-green-800: #558b2f;
  --light-green-900: #33691e;
  --light-green-a100: #ccff90;
  --light-green-a200: #b2ff59;
  --light-green-a400: #76ff03;
  --light-green-a700: #64dd17;
  /* Lime */
  --lime: #cddc39;
  --lime-50: #f9fbe7;
  --lime-100: #f0f4c3;
  --lime-200: #e6ee9c;
  --lime-300: #dce775;
  --lime-400: #d4e157;
  --lime-500: #cddc39;
  --lime-600: #c0ca33;
  --lime-700: #afb42b;
  --lime-800: #9e9d24;
  --lime-900: #827717;
  --lime-a100: #f4ff81;
  --lime-a200: #eeff41;
  --lime-a400: #c6ff00;
  --lime-a700: #aeea00;
  /* Yellow */
  --yellow: #ffeb3b;
  --yellow-50: #fffde7;
  --yellow-100: #fff9c4;
  --yellow-200: #fff59d;
  --yellow-300: #fff176;
  --yellow-400: #ffee58;
  --yellow-500: #ffeb3b;
  --yellow-600: #fdd835;
  --yellow-700: #fbc02d;
  --yellow-800: #f9a825;
  --yellow-900: #f57f17;
  --yellow-a100: #ffff8d;
  --yellow-a200: #ffff00;
  --yellow-a400: #ffea00;
  --yellow-a700: #ffd600;
  /* Amber */
  --amber: #ffc107;
  --amber-50: #fff8e1;
  --amber-100: #ffecb3;
  --amber-200: #ffe082;
  --amber-300: #ffd54f;
  --amber-400: #ffca28;
  --amber-500: #ffc107;
  --amber-600: #ffb300;
  --amber-700: #ffa000;
  --amber-800: #ff8f00;
  --amber-900: #ff6f00;
  --amber-a100: #ffe57f;
  --amber-a200: #ffd740;
  --amber-a400: #ffc400;
  --amber-a700: #ffab00;
  /* Orange */
  --orange: #ff9800;
  --orange-50: #fff3e0;
  --orange-100: #ffe0b2;
  --orange-200: #ffcc80;
  --orange-300: #ffb74d;
  --orange-400: #ffa726;
  --orange-500: #ff9800;
  --orange-600: #fb8c00;
  --orange-700: #f57c00;
  --orange-800: #ef6c00;
  --orange-900: #e65100;
  --orange-a100: #ffd180;
  --orange-a200: #ffab40;
  --orange-a400: #ff9100;
  --orange-a700: #ff6d00;
  /* Deep Orange */
  --deep-orange: #ff5722;
  --deep-orange-50: #fbe9e7;
  --deep-orange-100: #ffccbc;
  --deep-orange-200: #ffab91;
  --deep-orange-300: #ff8a65;
  --deep-orange-400: #ff7043;
  --deep-orange-500: #ff5722;
  --deep-orange-600: #f4511e;
  --deep-orange-700: #e64a19;
  --deep-orange-800: #d84315;
  --deep-orange-900: #bf360c;
  --deep-orange-a100: #ff9e80;
  --deep-orange-a200: #ff6e40;
  --deep-orange-a400: #ff3d00;
  --deep-orange-a700: #dd2c00;
  /* Brown */
  --brown: #795548;
  --brown-50: #efebe9;
  --brown-100: #d7ccc8;
  --brown-200: #bcaaa4;
  --brown-300: #a1887f;
  --brown-400: #8d6e63;
  --brown-500: #795548;
  --brown-600: #6d4c41;
  --brown-700: #5d4037;
  --brown-800: #4e342e;
  --brown-900: #3e2723;
  /* Grey */
  --grey: #9e9e9e;
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;
  /* Blue Grey */
  --blue-grey: #607d8b;
  --blue-grey-50: #eceff1;
  --blue-grey-100: #cfd8dc;
  --blue-grey-200: #b0bec5;
  --blue-grey-300: #90a4ae;
  --blue-grey-400: #78909c;
  --blue-grey-500: #607d8b;
  --blue-grey-600: #546e7a;
  --blue-grey-700: #455a64;
  --blue-grey-800: #37474f;
  --blue-grey-900: #263238;
  /* White / Black */
  --white: #ffffff;
  --black: #000000;
}

/*VARIABLES*/
/*DOMS*/
* {
  margin: 0;
  padding: 0;
}

html, body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: var(--base-font-size);
  font-family: var(--base-font);
}

body {
  overflow: hidden;
}

@media (max-width: 768px) {
  body {
    overflow-y: auto;
  }
}
body.inner {
  overflow-y: auto;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 999;
}

main {
  position: relative;
  margin: 0;
  padding: 0;
}
main section {
  position: relative;
  font-family: inherit;
  overflow: hidden;
  outline: none;
}
main section .content {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 1200px;
}

aside {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: inherit;
}
aside .container {
  padding: 1rem;
}

footer {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: inherit;
}
footer .container {
  padding: 1rem;
}

/*DISPLAY FLEX */
/*BACKGROUND*/
:root {
  --base-font: "Roboto", sans-serif;
  --base-font-size: 16px;
}

h1 {
  margin-bottom: 1rem;
  font-size: calc(3rem + 12 * (100vw - 300px) / 1300);
  font-weight: 700;
  text-transform: uppercase;
}
h1 span {
  display: none;
}
h1.text-left {
  text-align: left;
}

h2 {
  margin-bottom: 1rem;
  font-size: calc(2.6rem + 12 * (100vw - 300px) / 1300);
  font-weight: 400;
  text-transform: uppercase;
  color: #333e48;
}
h2 span {
  color: #96c940;
}

h3 {
  margin-bottom: 1rem;
  font-size: calc(1.5rem + 12 * (100vw - 300px) / 1300);
  font-weight: 300;
  text-transform: uppercase;
  color: #333e48;
}
h3 span {
  color: #96c940;
}

h4 {
  font-size: calc(1.2rem + 12 * (100vw - 300px) / 1300);
}

p {
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  font-weight: 300;
  line-height: 1.5;
  color: #333e48;
}
p.white {
  color: #ffffff;
}
p.text-left {
  text-align: left;
}
p.text-center {
  text-align: center;
}
p.text-right {
  text-align: right;
}

@media (max-width: 640px) {
  p {
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
  }
}
a {
  font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  text-decoration: none;
  color: #333e48;
}

@media (max-width: 640px) {
  a {
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
  }
}
i, em {
  color: #96c940;
}

em {
  font-weight: 400;
}

ul, ol {
  margin: 1rem 0;
  padding: 1rem 1rem 1rem 2rem;
}
ul li, ol li {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-weight: 300;
}

header {
  top: -10rem;
  width: 100%;
  transition: top 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
header .reso {
  position: fixed;
  text-align: center;
  width: 100%;
  background-color: #000;
  z-index: 9999;
  font-size: 0.8rem;
  color: #fff;
}
header .logo {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  width: 4rem;
  z-index: 11;
  transition: opacity 0.3s 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
header .logo.dark {
  opacity: 1;
}
header .logo.light {
  opacity: 0;
}
header.turn-white, header.dark {
  height: 0;
}
header.turn-white .logo.light, header.dark .logo.light {
  opacity: 1;
}
header.turn-white .logo.dark, header.dark .logo.dark {
  opacity: 0;
}
header .menu {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  width: 2.2rem;
  height: 2rem;
  cursor: pointer;
  z-index: 11;
}
header .menu span {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: #333e48;
}
header .menu span:before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #96c940;
}
header .menu span:nth-of-type(1) {
  top: 0.4rem;
  left: 0rem;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
header .menu span:nth-of-type(1):before {
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
header .menu span:nth-of-type(2) {
  top: 1.15rem;
  left: 0;
  transition: all 0.5s 0.1s cubic-bezier(1, 0.08, 0.35, 1);
}
header .menu span:nth-of-type(2):before {
  transition: all 0.5s 0.1s cubic-bezier(1, 0.08, 0.35, 1);
}
header .menu span:nth-of-type(3) {
  top: 2rem;
  left: 0;
  transition: all 0.5s 0.2s cubic-bezier(1, 0.08, 0.35, 1);
}
header .menu span:nth-of-type(3):before {
  transition: all 0.5s 0.2s cubic-bezier(1, 0.08, 0.35, 1);
}
header .menu.dark span, header .menu.show span {
  background-color: #fff;
}
header .menu.show span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0.75rem;
  left: 1.2rem;
  width: 50%;
}
header .menu.show span:nth-of-type(2) {
  transform: rotate(360deg);
}
header .menu.show span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 1.6rem;
  left: 1.2rem;
  width: 50%;
}
header .menu:hover span:before {
  width: 100%;
}

.show header {
  top: 0;
}

.inner header {
  top: 0;
  height: 5rem;
  background-color: #fff;
  transition: height 0.3s 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.inner header.turn-white {
  height: 0;
}

nav {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 10;
}
nav ul.outer-list {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-start;
  gap: 0;
  top: 0;
  right: -30rem;
  margin: 0;
  padding: 1rem;
  width: 25rem;
  height: 100%;
  background-color: #333e48;
  transition: right 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
nav ul.outer-list > li {
  margin: 0 8rem 1.5rem 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
nav ul.outer-list > li a {
  position: relative;
  padding: 0.5rem;
  color: #96c940;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  border-right: solid 0 #fff;
  transition: border 0.3s ease-out;
  isolation: isolate;
  overflow: hidden;
}
nav ul.outer-list > li a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));
  z-index: -2;
}
nav ul.outer-list > li a:after {
  content: attr(rel) "";
  display: block;
  position: absolute;
  padding-top: 0.8rem;
  padding-right: 0;
  top: 0;
  right: 0;
  color: #333e48;
  z-index: -1;
  font-size: inherit;
  font-weight: inherit;
  filter: blur(2px);
}
nav ul.outer-list > li a:before, nav ul.outer-list > li a:after {
  transition: all 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
nav ul.outer-list > li a:hover {
  border-right: solid 5px #fff;
}
nav ul.outer-list > li a:hover:before {
  width: 100%;
}
nav ul.outer-list > li a:hover:after {
  padding-right: 0.8rem;
}
nav ul.inner-list {
  margin: 0;
  padding: 0;
}
nav ul.inner-list > li {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav ul.inner-list > li a {
  position: relative;
  display: block;
  padding: 0.25rem 0.5rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
}
nav.show ul.outer-list {
  right: 0;
}

@media (max-width: 768px) {
  nav ul.outer-list {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
  }
  nav ul.outer-list > li {
    margin: 0 20% 0.5rem 0;
  }
}
main {
  width: 100%;
  height: auto;
}
main.primary {
  z-index: 0;
}
main.secondary {
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 -5rem 11rem 11rem rgb(51, 62, 72);
}
main .cross {
  position: fixed;
  display: block;
  width: 4rem;
  height: 4rem;
  background-image: url("../img/cross.svg");
  background-size: 100%;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 999;
  transition: top 0.5s, bottom 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main .cross:nth-of-type(1) {
  top: 1rem;
  left: 1rem;
}
main .cross:nth-of-type(2) {
  top: 1rem;
  right: 1rem;
}
main .cross:nth-of-type(3) {
  bottom: 1rem;
  left: 1rem;
}
main .cross:nth-of-type(4) {
  bottom: 1rem;
  right: 1rem;
}
main .three {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
main section.light {
  background-image: url("../img/grid.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: repeat;
  background-color: transparent;
}
main section.dark {
  background-image: url("../img/grid-2.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: repeat;
  background-color: transparent;
}
main section.preloader {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #fff;
}
main section.preloader .percentage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  font-family: "Arvo", sans-serif;
  font-size: 5rem;
  color: #96c940;
}
main section.preloader .percentage small {
  font-size: 2rem;
  color: #333e48;
}
main section.banner {
  margin: 0 auto;
  width: 100%;
  height: 250vh;
  isolation: isolate;
}
main section.banner .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 0;
}
main section.banner .content h1 {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 1rem;
  width: 100%;
  max-width: calc(1200px - 2rem);
  text-align: center;
}
main section.banner .content h1 img {
  display: inline-block;
  width: 10%;
  max-width: 15rem;
}
main section.banner .content h2.desk, main section.banner .content h2.mobile {
  display: inline-block;
  padding: 2rem;
}
main section.banner .content h2.desk em, main section.banner .content h2.mobile em {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}
main section.banner .content h2.desk em:before, main section.banner .content h2.mobile em:before {
  content: "";
  position: absolute;
  display: block;
  top: 0.75rem;
  left: calc(50% - 0.225rem);
  width: 0.45rem;
  height: 10rem;
  background-color: #333e48;
  animation-name: scroll-indicator-line;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
  z-index: 1;
}
main section.banner .content h2.desk em:after, main section.banner .content h2.mobile em:after {
  content: "";
  position: absolute;
  display: block;
  left: calc(50% - 2rem);
  bottom: -2rem;
  width: 4rem;
  height: 2.3rem;
  background-image: url("../img/arrow-head.svg");
  background-size: 100%;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
  animation-name: scroll-indicator-arrow;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
  z-index: 1;
}
main section.banner .content h2.mobile,
main section.banner .content .arrow-indicator {
  display: none;
}
main section.banner .marquee-clouds-1-outer,
main section.banner .marquee-clouds-2-outer,
main section.banner .marquee-clouds-3-outer {
  position: absolute;
  top: 50vh;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
main section.banner .marquee-clouds-1-outer .marquee-clouds-1-inner,
main section.banner .marquee-clouds-1-outer .marquee-clouds-2-inner,
main section.banner .marquee-clouds-1-outer .marquee-clouds-3-inner,
main section.banner .marquee-clouds-2-outer .marquee-clouds-1-inner,
main section.banner .marquee-clouds-2-outer .marquee-clouds-2-inner,
main section.banner .marquee-clouds-2-outer .marquee-clouds-3-inner,
main section.banner .marquee-clouds-3-outer .marquee-clouds-1-inner,
main section.banner .marquee-clouds-3-outer .marquee-clouds-2-inner,
main section.banner .marquee-clouds-3-outer .marquee-clouds-3-inner {
  width: 400vw;
  height: 100%;
  background-image: url("../img/clouds-1.svg");
  background-size: 100vw 200px;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: repeat-x;
  background-color: transparent;
  transform: translate3d(var(--move-initial), 0, 0);
  opacity: 0.3;
  --offset: 50vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  animation-name: marquee;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
main section.banner .marquee-clouds-2-outer {
  top: 40vh;
  height: 100vh;
}
main section.banner .marquee-clouds-2-outer .marquee-clouds-2-inner {
  background-image: url("../img/clouds-2.svg");
  background-size: 100vw 300px;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: repeat-x;
  background-color: transparent;
  opacity: 0.1;
  filter: blur(1px);
  animation-duration: 50s;
}
main section.banner .marquee-clouds-3-outer {
  top: 50vh;
  height: 100vh;
}
main section.banner .marquee-clouds-3-outer .marquee-clouds-3-inner {
  background-image: url("../img/clouds-3.svg");
  background-size: 100vw 300px;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: repeat-x;
  background-color: transparent;
  opacity: 0.08;
  filter: blur(2px);
  animation-duration: 80s;
}
main section.banner .trees, main section.banner .bldg-1, main section.banner .bldg-2, main section.banner .bldg-3, main section.banner .bldg-4, main section.banner .bldg-5 {
  position: absolute;
  width: 100%;
  height: 500px;
  bottom: -61%;
  background-image: url("../img/trees.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
main section.banner .bldg-1 {
  height: 80vh;
  bottom: -40%;
  background-image: url("../img/bldg-1.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
main section.banner .bldg-2 {
  height: 200vh;
  bottom: -42%;
  background-image: url("../img/bldg-2.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
main section.banner .bldg-3 {
  height: 100vh;
  bottom: -30%;
  background-image: url("../img/bldg-3.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
main section.banner .bldg-4 {
  height: 100vh;
  bottom: -35%;
  background-image: url("../img/bldg-4.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
main section.banner .bldg-5 {
  margin-left: -5%;
  width: 110%;
  height: 150vh;
  bottom: -160%;
  filter: blur(3px);
  background-image: url("../img/bldg-5.svg");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
main section.who,
main section.approach,
main section.purpose {
  height: 400vh;
}
main section.who .content,
main section.approach .content,
main section.purpose .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  margin-top: 250vh;
}
main section.who .content div,
main section.approach .content div,
main section.purpose .content div {
  margin: 10rem auto 0;
  width: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
}
main section.who .content div a,
main section.approach .content div a,
main section.purpose .content div a {
  display: none;
}
main section.who .three,
main section.approach .three,
main section.purpose .three {
  display: none;
}
main section.who {
  height: 800vh;
  background-color: #212931;
}
main section.who .content {
  margin-top: 50vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
}
main section.who .content div {
  width: 100%;
  background-color: transparent;
}
main section.who .content div p {
  color: #fff;
}
main section.who .content div p span {
  text-transform: uppercase;
  font-weight: 700;
  color: #96c940;
}
main section.who .content div ul {
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  width: calc(100% - 2rem);
}
main section.who .content div ul li {
  display: block;
  width: calc(20% - 1rem);
  font-size: calc(0.4rem + 12 * (100vw - 300px) / 1300);
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
main section.who .content div ul li:before {
  content: "";
  display: block;
  margin: 0 auto 1rem;
  width: calc(240px - 4rem);
  height: calc(240px - 4rem);
  border-radius: 100vmax;
}
main section.who .content div ul li:nth-of-type(1):before {
  background-image: url("../img/who/who-1.png");
  background-size: 50%;
  background-position: center;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: rgba(51, 62, 72, 0.5);
}
main section.who .content div ul li:nth-of-type(2):before {
  background-image: url("../img/who/who-2.png");
  background-size: 50%;
  background-position: center;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: rgba(51, 62, 72, 0.5);
}
main section.who .content div ul li:nth-of-type(3):before {
  background-image: url("../img/who/who-3.png");
  background-size: 50%;
  background-position: center;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: rgba(51, 62, 72, 0.5);
}
main section.who .content div ul li:nth-of-type(4):before {
  background-image: url("../img/who/who-4.png");
  background-size: 50%;
  background-position: center;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: rgba(51, 62, 72, 0.5);
}
main section.who .content div ul li:nth-of-type(5):before {
  background-image: url("../img/who/who-5.png");
  background-size: 50%;
  background-position: center;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: rgba(51, 62, 72, 0.5);
}
main section.teams {
  height: 200vh;
  background-color: #333e48;
}
main section.teams .marquee-title {
  margin-top: 65vh;
  padding-top: 10vh;
  width: 100%;
  height: 3.5rem;
  font-size: 3rem;
  overflow: hidden;
  color: #fff;
}
main section.teams .marquee-title .marquee-txt {
  position: absolute;
  border-style: solid none;
  border-width: 1px;
  border-color: #96c940;
  width: 5200px;
  height: 3.5rem;
  --offset: 650px;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  animation-name: marquee;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
main section.teams .marquee-title .marquee-txt span {
  position: absolute;
  display: inline-block;
  width: 2600px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}
main section.teams .marquee-title .marquee-txt span:nth-of-type(2) {
  left: 2600px;
}
main section.teams .content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  margin-top: 0;
  overflow: hidden;
  max-width: 100%;
  height: calc(100vh - 5rem);
}
main section.teams .content .col-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
  width: 100%;
}
main section.teams .content .col-container .col:not(.team-pics) {
  width: calc(50% - 2rem);
  max-width: 720px;
  text-align: right;
}
main section.teams .content .col-container .col:not(.team-pics) p {
  color: #fff;
}
main section.teams .content .col-container .view-all {
  margin-top: 2rem;
  padding: 1rem;
  width: calc(100vw - 2rem);
  max-width: 1200px;
  border-top: solid 1px #333e48;
  text-align: center;
}
main section.teams .content .col-container .view-all a {
  display: inline-block;
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-weight: 400;
  font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  text-transform: none;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: rgba(51, 62, 72, 0.5);
  color: #fff;
  background-color: #96c940;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.teams .content .col-container .view-all a:hover {
  color: #96c940;
  background-color: #fff;
}
main section.branches {
  height: 200vh;
  background-color: #333e48;
}
main section.branches .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  max-width: 100%;
  margin-top: 50vh;
}
main section.branches .content div {
  margin: 0 auto;
  width: 100%;
  height: 50%;
}
main section.branches .content div h3 {
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  text-align: center;
  z-index: 6;
  width: 50%;
  max-width: 1200px;
  color: #fff;
}
main section.branches .content div .bldg-3, main section.branches .content div .bldg-4 {
  position: absolute;
  left: 0;
  height: 100vh;
  width: calc(100% + 2rem);
  max-width: calc(100% + 2rem);
  background-color: #fff;
}
main section.branches .content div .bldg-3 {
  opacity: 0.3;
  bottom: 50vh;
  background-image: url("../img/bldg-3.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 2;
}
main section.branches .content div .bldg-4 {
  opacity: 0.1;
  bottom: 60vh;
  background-image: url("../img/bldg-4.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 1;
}
main section.branches .branch-link {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  top: 50vh;
  left: 0;
  padding-top: 50vh;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  isolation: isolate;
  z-index: 10;
  transition: opacity 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.branches .branch-link .content {
  display: none;
}
main section.branches .branch-link a {
  margin-top: calc(1rem + 12 * (100vw - 300px) / 1300);
  padding: 1rem 2rem;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: rgba(51, 62, 72, 0.5);
  backdrop-filter: blur(5px);
  color: #fff;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.branches .branch-link a:hover {
  color: #333e48;
  background-color: #96c940;
}
main section.branches .branch-link.who a {
  margin-top: 30vh;
}
main section.branches .branch-link .trees {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  opacity: 1;
  background-image: url("../img/branches/trees.svg");
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: rgba(51, 62, 72, 0);
  backdrop-filter: blur(0);
  z-index: 0;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.branches .branch-link .col-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
  margin-top: 0;
  width: 100%;
  height: 50vh;
  z-index: 1;
}
main section.branches .branch-link .col-container .col {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
  margin: 0;
  padding: 1rem;
  width: 33.3333333333%;
}
main section.branches .branch-link .col-container .col a {
  position: relative;
  padding: 1rem 3rem;
  font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  color: #fff;
  background-color: #96c940;
  border-radius: 100vmax 100vmax 100vmax 0;
  z-index: 1;
}
main section.branches .branch-link .col-container .col a:hover {
  color: #96c940;
  background-color: #fff;
}
main section.branches .branch-link .col-container .col a:hover + img {
  bottom: 1vh;
  opacity: 1;
}
main section.branches .branch-link .col-container .col img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.2;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.branches .branch-link:has(.col-container a:hover) .trees {
  backdrop-filter: blur(5px);
  background-color: rgba(51, 62, 72, 0.5);
}
main section.clients {
  padding-top: 10vh;
  height: 200vh;
  background-color: #333e48;
}
main section.clients .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  margin-top: 60vh;
}
main section.clients .content .text-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 0.5rem;
  margin: 0 auto 0;
  width: 50%;
  height: calc(50% - 5rem);
  max-width: 1200px;
}
main section.clients .content .text-content h3 {
  width: 100%;
  text-align: center;
}
main section.clients .content .text-content p {
  width: 100%;
  text-align: center;
  color: #fff;
}
main section.clients .content .slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
main section.clients .content .slider .slider-inner {
  position: absolute;
  display: block;
  top: 50%;
  margin-left: 100px;
  width: calc(100% - 200px);
  height: 10rem;
  overflow: hidden;
}
main section.clients .content .slider .slider-inner .slick-list {
  display: block;
  overflow: hidden;
  width: 100%;
}
main section.clients .content .slider .slider-inner .slick-slide,
main section.clients .content .slider .slider-inner .item-overlay {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  border-radius: 100vmax;
  background-color: #fff;
}
main section.clients .content .slider .slider-inner .slick-slide .img,
main section.clients .content .slider .slider-inner .item-overlay .img {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  filter: grayscale(1);
  transition: all 0.5s ease-out;
  cursor: pointer;
}
main section.clients .content .slider .slider-inner .slick-slide .img:hover,
main section.clients .content .slider .slider-inner .item-overlay .img:hover {
  overflow: hidden;
  filter: grayscale(0);
}
main section.clients .content .slider .slider-inner .slick-slide {
  margin: 0 0.5rem;
}
main section.clients .content .arrow-left, main section.clients .content .arrow-right {
  position: absolute;
  top: 105vh;
  left: 0;
  height: 10rem;
  width: 100px;
  z-index: 99;
  background-image: url("../img/arrow.svg");
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 0.3s, opacity 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.clients .content .arrow-left:hover, main section.clients .content .arrow-right:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
main section.clients .content .arrow-left.show, main section.clients .content .arrow-right.show {
  opacity: 1;
}
main section.clients .content .arrow-right {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}
main section.works {
  height: 200vh;
  background-color: #333e48;
  overflow: hidden;
}
main section.works .content {
  transform: translate3d(0px, 0px, 0px);
  margin-top: 50vh;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
}
main section.works .content .text-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  width: 50%;
  max-width: 1200px;
  height: calc(50% - 60px);
}
main section.works .content .text-content p {
  margin: 0 auto;
  color: #fff;
  text-align: center;
}
main section.works .content .slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
main section.works .content .slider .slider-inner {
  position: absolute;
  margin-left: 100px;
  top: calc(50% - 60px);
  width: calc(100% - 200px);
  height: 55%;
  overflow: hidden;
}
main section.works .content .slider .slider-inner .slick-list {
  display: block;
  overflow: hidden;
  width: 100%;
}
main section.works .content .slider .slider-inner .slick-slide,
main section.works .content .slider .slider-inner .item-overlay {
  position: relative;
  display: inline-block;
  width: 800px;
  height: 25rem;
  overflow: hidden;
}
main section.works .content .slider .slider-inner .slick-slide .img,
main section.works .content .slider .slider-inner .item-overlay .img {
  position: absolute;
  left: -100px;
  width: 1000px;
  height: 100%;
  background-size: cover;
  background-position: top center;
  filter: grayscale(1);
  transition: all 0.5s ease-out;
}
main section.works .content .slider .slider-inner .slick-slide .img:hover,
main section.works .content .slider .slider-inner .item-overlay .img:hover {
  left: 0;
  width: 100%;
  filter: grayscale(0);
}
main section.works .content .slider .slider-inner .slick-slide .bubble,
main section.works .content .slider .slider-inner .item-overlay .bubble {
  position: absolute;
  display: block;
  width: 100px;
  padding: 1rem 1.5rem;
  color: #fff;
  text-align: center;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: rgba(51, 62, 72, 0.8);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.works .content .slider .slider-inner .slick-slide .bubble em,
main section.works .content .slider .slider-inner .item-overlay .bubble em {
  display: block;
  font-size: 0.65rem;
  font-style: normal;
  text-transform: uppercase;
}
main section.works .content .slider .slider-inner .slick-slide .bubble.show,
main section.works .content .slider .slider-inner .item-overlay .bubble.show {
  opacity: 1;
}
main section.works .content .slider .slider-inner .slick-slide {
  margin: 0 0.5rem;
}
main section.works .content .arrow-left, main section.works .content .arrow-right {
  position: absolute;
  top: calc(50% - 60px);
  left: 0;
  height: 55%;
  width: 100px;
  z-index: 99;
  background-image: url("../img/arrow.svg");
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 0.3s, opacity 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
main section.works .content .arrow-left:hover, main section.works .content .arrow-right:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
main section.works .content .arrow-left.show, main section.works .content .arrow-right.show {
  opacity: 1;
}
main section.works .content .arrow-right {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}
main section.contact {
  height: 150vh;
}
main section.contact .map {
  margin: 1rem auto;
  width: 100%;
  height: 35vh;
  background-color: #212931;
  overflow: hidden;
}
main section.contact .map .mapouter,
main section.contact .map .gmap_canvas,
main section.contact .map iframe {
  width: 100%;
  height: 100%;
}
main section.contact .content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 0.5rem;
  margin-top: 50vh;
  width: 100%;
  max-width: 1200px;
}
main section.contact .content p {
  margin: 0 auto;
  width: 680px;
  text-transform: none;
  text-align: center;
  color: #fff;
}
main section.contact .content .btn {
  display: block;
  margin: 0 auto;
}
main section.contact:before {
  content: "";
  position: absolute;
  display: block;
  inset: 0;
  background-image: linear-gradient(#333e48, #1a1a1a, #1a1a1a);
  background-size: 100%;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: none;
  z-index: -1;
}

.show .cross:nth-of-type(1), .show .cross:nth-of-type(2),
.inner .cross:nth-of-type(1),
.inner .cross:nth-of-type(2) {
  top: 5rem;
}

.bottom .cross:nth-of-type(3), .bottom .cross:nth-of-type(4),
.inner .cross:nth-of-type(3),
.inner .cross:nth-of-type(4) {
  bottom: 5rem;
}

main.inner {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
main.inner section.banner {
  width: 100vw;
  height: 100vh;
}
main.inner section.banner:before, main.inner section.banner:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: calc(50% + 200px);
  height: 100%;
  background-image: url("../img/grid-2.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: repeat;
  background-color: transparent;
  background-color: #333e48;
  z-index: -1;
  mix-blend-mode: multiply;
}
main.inner section.banner:after {
  width: 100%;
  height: 100%;
  background-color: transparent;
  filter: grayscale(100%) blur(5px);
}
main.inner section.banner.about-us:after {
  background-image: url("../img/./about-us/about-us.png");
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner.about-us-process:after {
  background-image: url("../img/./about-us/process.jpg");
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner.about-us-team:after {
  background-image: url("../img/./about-us/team.jpg");
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner.services:after {
  background-image: url("../img/./services/services.jpg");
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner.branch:after {
  background-image: url("../img/./branches/banner.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner.work:after {
  background-image: url("../img/./works.jpg");
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner.careers:after {
  background-image: url("../img/./careers.jpg");
  background-size: 120%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.banner .content {
  margin-top: 5rem;
  width: 100%;
  max-width: 1200px;
  height: calc(100vh - 5rem);
}
main.inner section.banner .content .col-left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  padding: 2rem;
  width: calc(66.6666666667% - 4rem);
}
main.inner section.banner .content .col-left > div {
  display: inline-block;
  max-width: 30rem;
  text-align: center;
}
main.inner section.banner .content .col-left > div h1 {
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
  color: #fff;
  text-align: center;
}
main.inner section.banner .content .col-left > div p {
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
  color: #fff;
  text-align: center;
}
main.inner section.banner .content .col-left > div p span {
  font-weight: 700;
  text-transform: uppercase;
  color: #96c940;
}
main.inner section.banner .content .col-left > div a {
  font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
  text-align: center;
  text-transform: uppercase;
  color: #96c940;
}
main.inner section.container {
  background-image: url("../img/grid.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: fixed;
  background-repeat: repeat;
  background-color: transparent;
  width: 100vw;
  height: 100%;
}
main.inner section.container .content {
  position: relative;
  padding: 2rem 0;
  height: 100%;
  isolation: isolate;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
}
main.inner section.container .content.top-align {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
}
main.inner section.container .content .col, main.inner section.container .content .full {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  padding: 2rem;
  width: calc(50% - 4rem);
  height: auto;
  isolation: isolate;
}
main.inner section.container .content .col.left-minor, main.inner section.container .content .col.right-minor, main.inner section.container .content .full.left-minor, main.inner section.container .content .full.right-minor {
  width: calc(33.3333333333% - 4rem);
}
main.inner section.container .content .col.left-minor.no-padding, main.inner section.container .content .col.right-minor.no-padding, main.inner section.container .content .full.left-minor.no-padding, main.inner section.container .content .full.right-minor.no-padding {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  padding: 0;
  width: 33.3333333333%;
  height: 100%;
}
main.inner section.container .content .col.left-major, main.inner section.container .content .col.right-major, main.inner section.container .content .full.left-major, main.inner section.container .content .full.right-major {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  gap: 0;
  width: calc(66.6666666667% - 4rem);
}
main.inner section.container .content .col .serv, main.inner section.container .content .full .serv {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  padding: 1rem;
  width: 100%;
}
main.inner section.container .content .col .serv .img-container, main.inner section.container .content .full .serv .img-container {
  padding: 1rem;
  width: 10rem;
  background-color: #96c940;
  border-radius: 100vmax;
}
main.inner section.container .content .col .serv .img-container img, main.inner section.container .content .full .serv .img-container img {
  width: 8rem;
  object-fit: scale-down;
}
main.inner section.container .content .col .serv p, main.inner section.container .content .full .serv p {
  padding: 0 1rem;
  background-color: transparent;
}
main.inner section.container .content .col .serv p strong, main.inner section.container .content .full .serv p strong {
  font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
}
main.inner section.container .content .col h4, main.inner section.container .content .full h4 {
  display: block;
  width: 100%;
}
main.inner section.container .content .col p, main.inner section.container .content .full p {
  position: relative;
  margin: 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  background-color: #fff;
  font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  isolation: isolate;
}
main.inner section.container .content .col p.trans, main.inner section.container .content .full p.trans {
  background-color: transparent;
}
main.inner section.container .content .col p strong, main.inner section.container .content .full p strong {
  text-transform: uppercase;
}
main.inner section.container .content .col p span, main.inner section.container .content .full p span {
  font-weight: 700;
  color: #96c940;
}
main.inner section.container .content .col p[data-timeline]:before, main.inner section.container .content .full p[data-timeline]:before {
  content: attr(data-timeline);
  position: absolute;
  display: block;
  top: 6rem;
  right: -5.5rem;
  width: 20rem;
  height: 8rem;
  text-align: center;
  font-size: calc(12rem + 12 * (100vw - 300px) / 1300);
  font-weight: 900;
  color: #96c940;
  line-height: 1;
  opacity: 0.2;
  transform: rotate(-90deg);
  z-index: -1;
}
main.inner section.container .content .col img, main.inner section.container .content .full img {
  width: 100%;
}
main.inner section.container .content .col img.cir, main.inner section.container .content .full img.cir {
  object-fit: scale-down;
  border-radius: 100vmax;
  background-color: #fff;
}
main.inner section.container .content .col a.btn, main.inner section.container .content .full a.btn {
  flex: 1 1 100%;
  margin-top: 0;
}
main.inner section.container .content .full {
  width: 100%;
  max-width: 1200px;
}
main.inner section.container .content .full p {
  background-color: transparent;
}
main.inner section.container .content.mid-line:before, main.inner section.container .content.mid-line-dash:before {
  content: "";
  position: absolute;
  top: 0;
  left: 66.6666666667%;
  width: 2px;
  height: 100%;
  background-color: rgba(61, 52, 72, 0.2);
  z-index: -1;
}
main.inner section.container .content.mid-line-dash:before {
  top: 4rem;
  left: 200px;
  width: 0;
  height: 100%;
  border-left: dashed 2px #333e48;
  background-color: transparent;
}
main.inner section.container .content.timeline {
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  height: 100vh;
}
main.inner section.container .content.timeline .col.left-minor {
  padding: 2rem;
  width: calc(33.3333333333vw - 4rem);
  height: calc(33.3333333333vw - 4rem);
  max-height: 600px;
}
main.inner section.container .content.timeline .col.left-minor .icon {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border: solid 1rem #fff;
  box-shadow: 0 0.3rem 1rem rgba(33, 41, 49, 0.3);
}
main.inner section.container .content.timeline .col.left-minor.merlion .icon {
  background-image: url("../img/./about-us/merlion.jpg");
  background-size: 50%;
  background-position: leftcenter;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.container .content.timeline .col.left-minor.oriental-pearl .icon {
  background-image: url("../img/./about-us/shanghai-towers.jpg");
  background-size: 50%;
  background-position: left center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.container .content.timeline .col.left-minor.rizal .icon {
  background-image: url("../img/./about-us/rizal.jpg");
  background-size: 50%;
  background-position: left center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.container .content.timeline .col.left-minor.kuala .icon {
  background-image: url("../img/./about-us/kuala.jpg");
  background-size: 50%;
  background-position: left top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.container .content.timeline .col.left-minor.cryto .icon {
  background-image: url("../img/./about-us/cryto.jpg");
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.container .content.timeline .col.left-minor.iot .icon {
  background-image: url("../img/./about-us/iot.jpg");
  background-size: 50%;
  background-position: left center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: transparent;
}
main.inner section.container .content.timeline img {
  position: absolute;
  top: 50%;
  width: 50%;
}
main.inner section.container .content > img {
  width: calc(100% - 4rem);
}
main.inner section.container .content > img.modal {
  margin: 0 0.5rem;
  width: calc(33.3333333333% - 1rem);
  cursor: pointer;
}
main.inner section.container h3 {
  padding: 0.5rem 0;
  background-color: #fff;
}
main.inner section.container p {
  padding: 2rem;
}
main.inner section.container ul li {
  list-style: square;
  font-size: calc(0.8em + 12 * (100vw - 300px) / 1300);
  color: #333e48;
}
main.inner section.container.green {
  background-image: url("../img/grid-4.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: fixed;
  background-repeat: repeat;
  background-color: #96c940;
}
main.inner section.container.green .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
}
main.inner section.container.green .content h3 {
  background-color: transparent;
}
main.inner section.container.lgray-1 {
  background-image: url("../img/grid-3.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: fixed;
  background-repeat: repeat;
  background-color: #F3F3F3;
}
main.inner section.container.lgray-2 {
  background-image: none;
  background-color: #D9D9D9;
}
main.inner section.container.lgray-2 .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  padding: 0;
  height: 50vh;
}
main.inner section.container.lgray-2 .content .col {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  padding: 0 2rem;
}
main.inner section.container.lgray-2 .content .col p {
  background-color: transparent;
}
main.inner section.container.lgray-2 .content .col a {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  padding: 2rem;
  width: calc(100% - 4rem);
  height: 50%;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #96c940;
  color: #fff;
  overflow: hidden;
  isolation: isolate;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main.inner section.container.lgray-2 .content .col a:before {
  content: "";
  position: absolute;
  display: block;
  background-color: #212931;
  top: calc(50% - 15rem);
  left: -40rem;
  width: 30rem;
  height: 30rem;
  transform: rotate(45deg);
  z-index: -1;
  transition: left 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
main.inner section.container.lgray-2 .content .col a:nth-of-type(1) {
  height: calc(50% - 2px);
  border-bottom: solid 2px #D9D9D9;
}
main.inner section.container.lgray-2 .content .col a:hover {
  color: #96c940;
  background-color: #333e48;
}
main.inner section.container.lgray-2 .content .col a:hover:before {
  left: -12rem;
}
main.inner section.container.lgray-2 .content .col img {
  width: 5rem;
}
main.inner section.container.works-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  margin: 0 auto;
  width: calc(100% - 2rem);
  background-color: #F3F3F3;
}
main.inner section.container.works-grid h3 {
  margin: 0;
  width: 100%;
  background-color: transparent;
  text-align: center;
}
main.inner section.container.works-grid .grid-col {
  display: block;
  flex-grow: 1;
  flex-basis: 30%;
  flex-shrink: 1;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 30rem;
}
main.inner section.container.works-grid .grid-col a {
  display: block;
  margin: 1rem 0;
}
main.inner section.container.works-grid .grid-col a img {
  width: 100%;
}
main.inner section.careers-form {
  position: fixed;
  padding: 5rem 0;
  top: 0;
  right: -100%;
  width: 100%;
  height: calc(100% - 10rem);
  background-color: #fff;
  overflow-y: auto;
  background-image: url("../img/grid.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: fixed;
  background-repeat: repeat;
  background-color: #FFFFFF;
  transition: right 0.5s cubic-bezier(1, 0.08, 0.35, 1);
  z-index: 99;
}
main.inner section.careers-form .content {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 0;
}
main.inner section.careers-form .content h2, main.inner section.careers-form .content p {
  text-align: center;
}
main.inner section.careers-form .content .form-content {
  padding-top: 1rem;
}
main.inner section.careers-form .content .back {
  position: absolute;
  top: 50%;
  left: 1rem;
  width: 70px;
  height: 70px;
  background-image: url("../img/arrow.svg");
  background-size: 70% 70%;
  background-position: center;
  background-attachment: initial;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
main.inner section.careers-form .content .back:hover {
  background-color: #eee;
}
main.inner section.careers-form.show {
  right: 0;
}
main.inner section.contact {
  width: 100vw;
  height: auto;
}
main.inner section.contact h1 {
  position: relative;
  margin-top: 10rem;
  margin-bottom: 0;
  color: #fff;
  text-align: center;
  z-index: 1;
}
main.inner section.contact .content {
  margin: 0 auto 1rem;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
}
main.inner section.contact .content p {
  margin: 0 auto 2rem;
}
main.inner section.contact .content > div:not(.form-content) {
  padding: 2rem 0;
}
main.inner section.thank-you {
  padding-top: 3rem;
  width: 100vw;
  height: 100vh;
  background-image: url("../img/grid.svg");
  background-size: 50px 50px;
  background-position: initial;
  background-attachment: fixed;
  background-repeat: repeat;
  background-color: transparent;
}
main.inner section.thank-you .content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 0;
  padding: 0 2rem;
  width: calc(100% - 4rem);
  height: 100%;
}
main.inner section.thank-you .content h1 {
  color: #96c940;
  text-align: center;
}
main.inner section.thank-you .content h2 {
  text-align: center;
  font-size: calc(1.2em + 12 * (100vw - 300px) / 1300);
  text-transform: none;
}
main.inner section.thank-you .content img {
  width: 100%;
  max-width: 10rem;
}
main.inner .btn {
  display: block;
  margin: 2rem auto 0;
  width: calc(100% - 4rem);
  max-width: 300px;
}

aside {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  top: 0;
  left: 0;
  width: 1rem;
  height: 100vh;
  z-index: 20;
}
aside .scroll-indicator-container {
  position: relative;
  width: 100%;
}
aside .scroll-indicator-container ul {
  position: relative;
  margin: 0;
  padding: 0;
}
aside .scroll-indicator-container ul li {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 0;
  list-style: none;
  text-align: center;
}
aside .scroll-indicator-container ul li a {
  position: relative;
  display: inline-block;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.7rem;
  line-height: 1;
  text-transform: uppercase;
  background-color: rgba(100, 100, 100, 0);
  transition: background-color 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
aside .scroll-indicator-container ul li a span {
  position: absolute;
  top: 0.75rem;
  left: -10rem;
  display: block;
  margin-left: 1rem;
  padding: 0.25rem 1rem;
  width: 6rem;
  text-align: center;
  color: #96c940;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: rgba(51, 62, 72, 0.8);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
aside .scroll-indicator-container ul li a:before {
  content: "";
  position: relative;
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border: solid 1px #ccc;
  border-radius: 100vmax;
  transition: background-color 0.3s, border 1s cubic-bezier(1, 0.08, 0.35, 1);
}
aside .scroll-indicator-container ul li a.current:before {
  background-color: #96c940;
}
aside .scroll-indicator-container ul li a:hover:not(.current):before {
  background-color: rgba(100, 100, 100, 0.3);
}
aside .scroll-indicator-container ul li a:hover span {
  opacity: 1;
  left: 0;
}
aside .scroll-indicator-container.dark ul li a span {
  color: #fff;
  background-color: rgba(150, 201, 64, 0.8);
}
aside .scroll-indicator-container.dark ul li a:before {
  border: solid 1px #555;
}

footer {
  margin: 0;
  padding: 0 1rem;
  height: auto;
  background-color: #1a1a1a;
}
footer .map {
  margin-left: -1rem;
  width: calc(100% + 2rem);
  height: 50vh;
  background-color: #212931;
  overflow: hidden;
}
footer .map .map-container {
  overflow: hidden;
  resize: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
footer .map .map-container #embed-ded-map-canvas {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
footer .map .mapouter,
footer .map .gmap_canvas,
footer .map iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
footer .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
  padding-top: 1rem;
  width: 100%;
}
footer .content .side-col {
  padding: 1rem;
  width: calc(30% - 2rem);
}
footer .content .side-col:nth-of-type(1) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
}
footer .content .side-col:nth-of-type(1) img {
  width: 10rem;
}
footer .content .side-col:nth-of-type(3) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 0;
  padding-left: 3rem;
  border-left: solid 1px #fff;
}
footer .content .side-col:nth-of-type(3) ul {
  margin: 0;
  padding: 0;
}
footer .content .side-col:nth-of-type(3) ul li {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}
footer .content .side-col:nth-of-type(3) ul li a {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
  background-color: #1a1a1a;
  border-radius: 100vmax 100vmax 100vmax 0;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
footer .content .side-col:nth-of-type(3) ul li a:hover {
  color: #333e48;
  background-color: #96c940;
}
footer .content .side-col:nth-of-type(3) .return {
  display: none;
}
footer .content .mid-col {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  padding: 1rem;
  width: calc(70% - 2rem);
}
footer .content .mid-col .col {
  padding: 1rem;
  width: calc(33.3333333333% - 2rem);
}
footer .content .mid-col .col p {
  color: #fff;
}
footer .content .mid-col .col p.title {
  padding: 0.5rem 0;
  font-size: 1.2rem;
  border-bottom: solid 1px #fff;
}
footer .content .mid-col .col p.title span {
  color: #96c940;
}
footer .content .mid-col .col p.address {
  font-size: 0.8rem;
}
footer .content .mid-col .col.twin .address {
  padding: 0 1rem;
}
footer .content .mid-col .col.twin:nth-of-type(1) {
  padding-right: 0;
}
footer .content .mid-col .col.twin:nth-of-type(2) {
  padding-left: 0;
}
footer .content .mid-col .col.twin:nth-of-type(2) .title strong {
  opacity: 0;
}
footer .bot-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  gap: 0;
  margin-top: 2rem;
  padding: 1rem;
  border-top: solid 1px #fff;
}
footer .bot-content div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
}
footer .bot-content div a {
  color: #fff;
  font-size: 0.8rem;
}
footer .bot-content div span {
  display: inline-block;
  margin: 0 1rem;
  border-left: solid 1px #555;
  height: 2rem;
}
footer .bot-content p {
  margin: 0;
  font-size: 0.8rem;
  color: #fff;
}

.form-content .form-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  width: 50%;
  margin: 0 auto;
}
.form-content .form-group .col {
  width: calc(50% - 1rem);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
}
.form-content .form-group input,
.form-content .form-group textarea,
.form-content .form-group .select {
  position: relative;
  display: inline-block;
  padding: 1rem 1rem;
  width: calc(100% - (2rem + 4px));
  font-size: 1rem;
  font-family: inherit;
  color: #96c940;
  border: solid 2px #96c940;
  border-radius: 1.5rem;
  background-color: #212931;
}
.form-content .form-group input[type=text]::placeholder,
.form-content .form-group input[type=email]::placeholder,
.form-content .form-group textarea::placeholder {
  color: #aaa;
}
.form-content .form-group select {
  display: none;
}
.form-content .form-group .select {
  cursor: pointer;
  z-index: 1;
  color: #96c940;
}
.form-content .form-group .select .arrow {
  position: absolute;
  display: block;
  top: 1rem;
  right: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  background-image: url("../img/arrow-down.svg");
  background-size: 100%;
  background-position: initial;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
}
.form-content .form-group .select .picked {
  display: block;
  white-space: nowrap;
  width: calc(100% - 2rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-content .form-group .select .list {
  display: none;
  position: absolute;
  width: 120%;
  height: 10rem;
  left: 0;
  bottom: -10.5rem;
  font-size: 1rem;
  font-family: inherit;
  color: #96c940;
  border: solid 1px #333e48;
  background-color: #212931;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}
.form-content .form-group .select .list ul {
  margin: 0;
  padding: 1rem;
  height: calc(100% - 2rem);
  overflow-y: auto;
}
.form-content .form-group .select .list ul li {
  margin: 0;
  padding: 0.5rem;
  list-style: none;
  color: #96c940;
  transition: all 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
.form-content .form-group .select .list ul li:hover {
  background-color: #96c940;
  color: #212931;
}
.form-content .form-group .select .list.show {
  display: block;
}
.form-content .form-group .select[data-status=def] {
  color: #aaa;
}
.form-content .form-group textarea {
  resize: none;
  height: 3rem;
  margin: 0;
  overflow: hidden;
}
.form-content .form-group .recaptcha {
  position: absolute;
  right: 0;
  visibility: hidden;
}
.form-content .form-group label {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: calc(0.2rem + 12 * (100vw - 300px) / 1300);
  text-transform: uppercase;
  color: #333e48;
}
.form-content .form-group .email-notif {
  margin-top: 1rem;
  display: block;
  padding: 0.5rem 0.25rem;
  background-color: red;
  font-size: calc(0.2rem + 12 * (100vw - 300px) / 1300);
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 100vmax;
  background-color: #96c940;
  line-height: 1;
  opacity: 0;
  transition: 0.3s opacity cubic-bezier(1, 0.08, 0.35, 1);
}
.form-content .form-group .email-notif.error {
  background-color: red;
}
.form-content .form-group .email-notif.show {
  opacity: 1;
}
.form-content .form-group.light input {
  color: #333e48;
  background-color: #fff;
}

.inner .form-content {
  padding-top: 5rem;
}

@media (max-width: 640px) {
  .form-content .form-group {
    margin-top: 2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
    width: 80%;
  }
  .form-content .form-group .col {
    gap: 0;
    width: 100%;
    margin-top: 0;
  }
  .form-content .form-group input,
  .form-content .form-group .select {
    padding: 0.5rem 1rem;
    height: 20px;
    width: calc(100% - 2rem);
    margin: 0.5rem 0;
  }
  .form-content .form-group input .arrow,
  .form-content .form-group .select .arrow {
    top: 0.5rem;
  }
  .form-content .form-group input .list,
  .form-content .form-group .select .list {
    width: 100%;
  }
  .form-content .form-group textarea {
    margin: 0.5rem 0;
  }
  .form-content .form-group .email-notif {
    opacity: 0;
    margin: 0 auto 1rem !important;
    font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  }
  .form-content .form-group label {
    font-size: calc(0.8rem + 12 * (100vw - 300px) / 1300);
  }
}
.btn {
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  color: #333e48;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  border: none;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: #96c940;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.btn:hover {
  color: #fff;
  background-color: #333e48;
}

@media (max-width: 640px) {
  .btn {
    margin: 1rem 0;
  }
}
.slider-control {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}
.slider-control .content {
  display: none;
}
.slider-control .slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4200px;
  z-index: 10;
}
.slider-control .slider-overlay .slider-inner-overlay {
  position: absolute;
  padding: 0 10rem;
  display: flex;
  top: calc(50% - 60px);
  width: 100%;
  height: 55%;
  justify-content: space-around;
}
.slider-control .slider-overlay .slider-inner-overlay .item .img {
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.slider-control .slider-overlay .slider-inner-overlay .item .img.over {
  left: 0;
  width: 100%;
  filter: grayscale(0);
}
.slider-control .slider-overlay .slider-inner-overlay .item-overlay {
  position: relative;
  width: 800px;
  height: 100%;
  cursor: pointer;
}
.slider-control .slider-overlay .slider-inner-overlay .item-overlay .bubble {
  position: absolute;
  display: block;
  width: 100px;
  padding: 1rem 1.5rem;
  color: #fff;
  text-align: center;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: rgba(51, 62, 72, 0.8);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
.slider-control .slider-overlay .slider-inner-overlay .item-overlay .bubble em {
  display: block;
  font-size: 0.65rem;
  font-style: normal;
  text-transform: uppercase;
}
.slider-control .slider-overlay .slider-inner-overlay .item-overlay .bubble.show {
  opacity: 1;
}
.slider-control .arrow-left, .slider-control .arrow-right {
  position: absolute;
  top: calc(50% - 60px);
  left: 0;
  height: 55%;
  width: 100px;
  z-index: 99;
  background-image: url("../img/arrow.svg");
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: background-color 0.3s, opacity 0.3s cubic-bezier(1, 0.08, 0.35, 1);
}
.slider-control .arrow-left:hover, .slider-control .arrow-right:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.slider-control .arrow-left.show, .slider-control .arrow-right.show {
  opacity: 1;
}
.slider-control .arrow-right {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}

.slider-control.clients {
  top: 100%;
}
.slider-control.clients .slider-overlay {
  position: absolute;
  width: 180rem;
}
.slider-control.clients .slider-overlay .slider-inner-overlay {
  top: 50%;
  height: 10rem;
}
.slider-control.clients .slider-overlay .slider-inner-overlay .item-overlay {
  width: 10rem;
  height: 10rem;
  border-radius: 100vmax;
  overflow: hidden;
}
.slider-control.clients .arrow-left, .slider-control.clients .arrow-right {
  top: 50%;
  height: 10rem;
}

@media (max-width: 768px) {
  .slider-control {
    background-image: url("../img/grid-2.svg");
    background-size: 50px 50px;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: repeat;
    background-color: #333e48;
  }
  .slider-control .content {
    padding: 0 1rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 1rem;
    width: calc(100% - 2rem);
    height: 50vh;
    text-align: center;
  }
  .slider-control .content p {
    width: 80%;
    color: #fff;
  }
  .slider-control .slider-overlay {
    width: 500vw;
  }
  .slider-control .slider-overlay .slider-inner-overlay {
    padding: 0;
    top: calc(60% - 50px);
    height: 30%;
    justify-content: space-around;
  }
  .slider-control .slider-overlay .slider-inner-overlay .item-overlay {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 1rem;
    width: 80vw;
    height: 100%;
  }
  .slider-control .slider-overlay .slider-inner-overlay .item-overlay .bubble {
    display: none;
  }
  .slider-control .slider-overlay .slider-inner-overlay .item-overlay img {
    width: 100%;
  }
  .slider-control .arrow-left, .slider-control .arrow-right {
    width: 50px;
    background-size: 20px;
  }
  .slider-control .arrow-left:hover, .slider-control .arrow-right:hover {
    background-color: transparent;
  }
}
.team-pics {
  width: calc(50% - 4rem);
  max-width: 50%;
  height: calc(100% - 2.5rem);
  z-index: 3;
  transition: opacity 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.team-pics .text-content {
  display: none;
}
.team-pics .team-content {
  position: relative;
  height: calc(50% + 6.5rem);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-end;
  gap: 2rem;
}
.team-pics .team-content .pic {
  position: relative;
  margin: 0 0.25rem;
  width: calc(13.3333333333vw - 1rem);
  height: calc(13.3333333333vw - 1rem);
  border-radius: 0 0 100vmax 100vmax;
  overflow: hidden;
  cursor: pointer;
}
.team-pics .team-content .pic .profile-norm {
  position: absolute;
  bottom: -3vw;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: bottom 0.5s 0.4s cubic-bezier(1, 0.08, 0.35, 1), opacity 0.5s 0.2s cubic-bezier(1, 0.08, 0.35, 1);
}
.team-pics .team-content .pic .profile-pop {
  position: absolute;
  bottom: -3vw;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: bottom 0.5s 0.4s cubic-bezier(1, 0.08, 0.35, 1), opacity 0.5s 0.2s cubic-bezier(1, 0.08, 0.35, 1);
}
.team-pics .team-content .pic .bubble {
  position: absolute;
  bottom: -150%;
  left: 0;
  width: calc(100% - 2rem);
  padding: 1rem 1rem 2rem;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: #96c940;
  backdrop-filter: blur(5px);
  transform: rotate(90deg);
  opacity: 0;
  z-index: -2;
  transition: bottom 0.5s 0.5s, opacity 0.5s 0.7s, transform 0.4s 0.6s cubic-bezier(1, 0.08, 0.35, 1);
}
.team-pics .team-content .pic .bubble p {
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
}
.team-pics .team-content .pic .bubble p.name {
  text-transform: uppercase;
  font-weight: 700;
  color: #333e48;
}
.team-pics .team-content .pic .bubble p.position {
  font-weight: 700;
}
.team-pics .team-content .pic:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(13.3333333333vw - 1rem - 2px);
  height: calc(13.3333333333vw - 1rem - 2px);
  border: solid 1px #fff;
  border-radius: 100vmax;
  background-color: #fff;
  z-index: -3;
}
.team-pics .team-content .pic:hover {
  overflow: hidden;
  margin-top: calc((13.3333333333vw - 1rem) * -1);
  height: calc((13.3333333333vw - 1rem) * 2);
}
.team-pics .team-content .pic:hover .profile-pop {
  opacity: 1;
  bottom: -1rem;
}
.team-pics .team-content .pic:hover .profile-norm {
  opacity: 0;
  bottom: -1rem;
}
.team-pics .team-content .pic:hover .bubble {
  bottom: calc(13.3333333333vw - 1rem);
  transform: rotate(0deg);
  opacity: 1;
}
.team-pics .team-content .pic:nth-of-type(1), .team-pics .team-content .pic:nth-of-type(1):before {
  transition: all 1s cubic-bezier(1, 0.08, 0.35, 1);
}
.team-pics .team-content .pic:nth-of-type(2), .team-pics .team-content .pic:nth-of-type(2):before {
  transition: all 0.8s 0.2s cubic-bezier(1, 0.08, 0.35, 1);
}
.team-pics .team-content .pic:nth-of-type(3), .team-pics .team-content .pic:nth-of-type(3):before {
  transition: all 0.6s 0.4s cubic-bezier(1, 0.08, 0.35, 1);
}

@media (max-width: 768px) {
  .team-pics {
    width: calc(100% - 4rem);
    max-width: calc(100% - 4rem);
  }
  .team-pics .team-content {
    margin-top: 5rem;
    height: 12rem;
    z-index: 1;
  }
  .team-pics .team-content .pic {
    width: calc(33.3333333333vw - 2rem);
    height: calc(33.3333333333vw - 2rem);
  }
  .team-pics .team-content .pic .profile-norm {
    bottom: -5vh;
  }
  .team-pics .team-content .pic .bubble {
    width: calc(100% - 1rem);
    padding: 0.5rem 0.5rem 1rem;
  }
  .team-pics .team-content .pic .bubble p {
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
  }
  .team-pics .team-content .pic .bubble p.name {
    margin: 0;
    font-weight: 700;
  }
  .team-pics .team-content .pic .bubble p.position {
    margin: 0;
    font-weight: 300;
  }
  .team-pics .team-content .pic:before {
    width: calc(33.3333333333vw - 2rem - 2px);
    height: calc(33.3333333333vw - 2rem - 2px);
  }
  .team-pics .team-content .pic:hover {
    margin-top: calc((33.3333333333vw - 1rem) * -1);
    height: calc((33.3333333333vw - 1.5rem) * 2);
  }
  .team-pics .team-content .pic:hover .bubble {
    bottom: calc(33.3333333333vw - 1.5rem);
    transform: rotate(0deg);
  }
  .team-pics .view-all {
    margin-left: 0;
    width: calc(100% - 2rem);
  }
  .team-pics .view-all a {
    text-align: center;
  }
}
@media (max-width: 640px) {
  .team-pics .team-content {
    margin-top: -5rem;
  }
}
.link {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0;
  top: 5;
  left: 0;
  padding-top: 50vh;
  width: 100%;
  height: 50vh;
  opacity: 0;
  overflow: hidden;
  isolation: isolate;
  z-index: 10;
  transition: opacity 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.link .content {
  display: none;
}
.link a {
  margin-top: calc(1rem + 12 * (100vw - 300px) / 1300);
  padding: 1rem 2rem;
  border-radius: 100vmax 100vmax 100vmax 0;
  background-color: rgba(51, 62, 72, 0.5);
  backdrop-filter: blur(5px);
  color: #fff;
  transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.link a:hover {
  color: #333e48;
  background-color: #96c940;
}
.link.who a {
  margin-top: 30vh;
}
.link.show {
  opacity: 1;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .link {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 1rem;
    height: 100vh;
    padding-top: 0;
  }
  .link .content {
    display: block;
    padding: 0 2rem 1rem;
    width: calc(50% - 4rem);
  }
  .link .content a {
    display: block;
    margin: 3rem auto 0;
    width: 70%;
    text-align: center;
  }
  .link .content p {
    color: #333e48;
    text-align: center;
  }
  .link .content p span {
    font-weight: 700;
    text-transform: uppercase;
    color: #96c940;
  }
  .link .content p.dark {
    color: #333e48;
  }
  .link .content h3 {
    width: 100%;
    text-align: center;
  }
  .link .content h3.dark {
    color: #fff;
  }
  .link > a {
    display: none;
  }
  .link.who .content {
    width: calc(100% - 4rem);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 1rem;
  }
  .link.who .content div {
    width: 100%;
    background-color: transparent;
  }
  .link.who .content div p {
    margin: 1rem auto;
    width: 50%;
    color: #fff;
  }
  .link.who .content div p span {
    text-transform: uppercase;
    font-weight: 700;
    color: #96c940;
  }
  .link.who .content div a {
    margin: 1rem auto 0;
  }
  .link.who .content div ul {
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.5rem;
    width: calc(100% - 2rem);
  }
  .link.who .content div ul li {
    display: block;
    width: calc(20% - 1rem);
    font-size: calc(0.4rem + 12 * (100vw - 300px) / 1300);
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
  }
  .link.who .content div ul li:before {
    content: "";
    display: block;
    margin: 0 auto 1rem;
    width: calc(240px - 6rem);
    height: calc(240px - 6rem);
    border-radius: 100vmax;
  }
  .link.who .content div ul li:nth-of-type(1):before {
    background-image: url("../img/who/who-1.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  .link.who .content div ul li:nth-of-type(2):before {
    background-image: url("../img/who/who-2.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  .link.who .content div ul li:nth-of-type(3):before {
    background-image: url("../img/who/who-3.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  .link.who .content div ul li:nth-of-type(4):before {
    background-image: url("../img/who/who-4.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  .link.who .content div ul li:nth-of-type(5):before {
    background-image: url("../img/who/who-5.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
}
.modal-container {
  position: fixed;
  display: none;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  z-index: 999;
}
.modal-container .modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
}
.modal-container .modal-content .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: #96c940;
  cursor: pointer;
  transition: background-color 0.5s cubic-bezier(1, 0.08, 0.35, 1);
}
.modal-container .modal-content .close:before, .modal-container .modal-content .close:after {
  content: "";
  position: absolute;
  display: block;
  top: 0.25rem;
  left: 50%;
  width: 2px;
  height: 2.5rem;
  background-color: #fff;
  transform: rotate(45deg);
}
.modal-container .modal-content .close:after {
  transform: rotate(-45deg);
}
.modal-container .modal-content .close:hover {
  background-color: #333e48;
}
.modal-container .modal-content img {
  width: 50vw;
}

@media (max-width: 768px) {
  .modal-container .modal-content img {
    width: 100%;
  }
}
@keyframes scroll-indicator-line {
  from {
    bottom: 1rem;
    height: 2.3rem;
  }
  to {
    bottom: -9rem;
    height: 12rem;
  }
}
@keyframes scroll-indicator-arrow {
  from {
    bottom: -2rem;
  }
  to {
    bottom: -10rem;
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@media (max-width: 1024px) {
  :root {
    --base-font-size: 14px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  main section.who .content,
  main section.approach .content,
  main section.purpose .content {
    display: none;
  }
}
@media (max-width: 768px) {
  :root {
    --base-font-size: 16px;
  }
  header, header.dark {
    top: 0;
    height: 5rem;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  header.dark {
    background-color: rgba(51, 62, 72, 0.3);
  }
  aside {
    display: none;
  }
  main.secondary {
    box-shadow: 0 -1rem 2rem 2rem rgb(51, 62, 72);
  }
  main .cross {
    width: 2.5rem;
    height: 2.5rem;
  }
  main .cross:nth-of-type(1), main .cross:nth-of-type(2) {
    top: 5rem;
  }
  main .three {
    display: none;
  }
  main section.dark {
    margin-top: -2px;
    border-top: solid 1px #333e48;
  }
  main section.banner {
    height: 150vh;
  }
  main section.banner .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
  }
  main section.banner .content h1 {
    position: relative;
    padding-top: 5rem;
    width: calc(100% - 2rem);
  }
  main section.banner .content h1 img {
    width: 10rem;
  }
  main section.banner .content h2.mobile {
    display: inline-block;
    padding: 0 2rem;
    font-size: calc(1.8rem + 12 * (100vw - 300px) / 1300);
  }
  main section.banner .content h2.desk {
    display: none;
  }
  main section.banner .content h2.desk em {
    display: none;
  }
  main section.banner .content .arrow-indicator {
    position: absolute;
    display: block;
    top: 75vh;
    left: calc(50% - 0.225rem);
    width: 0.45rem;
    height: 5rem;
    background-color: #333e48;
    animation-name: scroll-indicator-line-mobile;
    animation-duration: 0.8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
    z-index: 1;
  }
  main section.banner .content .arrow-indicator:after {
    content: "";
    position: absolute;
    display: block;
    left: calc(50% - 2rem);
    bottom: -2rem;
    width: 4rem;
    height: 2.3rem;
    background-image: url("../img/arrow-head.svg");
    background-size: 100%;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    animation-name: scroll-indicator-arrow-mobile;
    animation-duration: 0.8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0.08, 0.35, 1);
    z-index: 1;
  }
  main section.banner .marquee-clouds-1-outer,
  main section.banner .marquee-clouds-2-outer,
  main section.banner .marquee-clouds-3-outer {
    top: 15vh;
  }
  main section.banner .marquee-clouds-1-outer .marquee-clouds-1-inner,
  main section.banner .marquee-clouds-1-outer .marquee-clouds-2-inner,
  main section.banner .marquee-clouds-1-outer .marquee-clouds-3-inner,
  main section.banner .marquee-clouds-2-outer .marquee-clouds-1-inner,
  main section.banner .marquee-clouds-2-outer .marquee-clouds-2-inner,
  main section.banner .marquee-clouds-2-outer .marquee-clouds-3-inner,
  main section.banner .marquee-clouds-3-outer .marquee-clouds-1-inner,
  main section.banner .marquee-clouds-3-outer .marquee-clouds-2-inner,
  main section.banner .marquee-clouds-3-outer .marquee-clouds-3-inner {
    width: 2000vw;
    background-image: url("../img/clouds-1.svg");
    background-size: 500vw 400px;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: repeat-x;
    background-color: transparent;
    --offset: 250vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    animation-name: marquee;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  main section.banner .marquee-clouds-2-outer {
    top: 20vh;
  }
  main section.banner .marquee-clouds-2-outer .marquee-clouds-2-inner {
    animation-duration: 50s;
  }
  main section.banner .marquee-clouds-3-outer {
    top: 10vh;
    height: 100vh;
  }
  main section.banner .marquee-clouds-3-outer .marquee-clouds-3-inner {
    background-image: url("../img/clouds-3.svg");
    background-size: 500vw 500px;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: repeat-x;
    background-color: transparent;
    opacity: 0.08;
    filter: blur(2px);
    animation-duration: 80s;
  }
  main section.banner .trees, main section.banner .bldg-1, main section.banner .bldg-2, main section.banner .bldg-3, main section.banner .bldg-4, main section.banner .bldg-5 {
    bottom: 8%;
    background-image: url("../img/trees.png");
    background-size: 200% auto;
    background-position: center bottom;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 4;
  }
  main section.banner .bldg-1 {
    bottom: 17%;
    background-image: url("../img/bldg-1.png");
    background-size: 200% auto;
    background-position: center bottom;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 3;
  }
  main section.banner .bldg-2 {
    bottom: 17%;
    background-image: url("../img/bldg-2.png");
    background-size: 200% auto;
    background-position: center bottom;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 2;
  }
  main section.banner .bldg-3 {
    bottom: 30%;
    background-image: url("../img/bldg-3.png");
    background-size: 200% auto;
    background-position: center bottom;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 1;
  }
  main section.banner .bldg-4 {
    bottom: 35%;
    background-image: url("../img/bldg-4.png");
    background-size: 200% auto;
    background-position: center bottom;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 0;
  }
  main section.banner .bldg-5 {
    margin-left: -5%;
    height: 100vh;
    bottom: -10%;
    background-image: url("../img/bldg-5.svg");
    background-size: 200% auto;
    background-position: center bottom;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 5;
  }
  main section.who,
  main section.approach,
  main section.purpose {
    isolation: isolate;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 1rem;
    height: 100vh;
  }
  main section.who .content,
  main section.approach .content,
  main section.purpose .content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1rem;
    margin-top: 1rem;
    z-index: 1;
  }
  main section.who .content div,
  main section.approach .content div,
  main section.purpose .content div {
    margin-top: 5rem;
    width: 80%;
  }
  main section.who .content div a,
  main section.approach .content div a,
  main section.purpose .content div a {
    display: block;
    margin-top: calc(1rem + 12 * (100vw - 300px) / 1300);
    padding: 1rem 2rem;
    border-radius: 100vmax 100vmax 100vmax 0;
    background-color: rgba(51, 62, 72, 0.5);
    backdrop-filter: blur(5px);
    color: #fff;
    transition: all 0.5s cubic-bezier(1, 0.08, 0.35, 1);
  }
  main section.who .content div a:hover,
  main section.approach .content div a:hover,
  main section.purpose .content div a:hover {
    color: #333e48;
    background-color: #96c940;
  }
  main section.who .three,
  main section.approach .three,
  main section.purpose .three {
    position: absolute;
    display: block;
    inset: 0;
    z-index: -1;
  }
  main section.who a {
    margin-top: 0;
  }
  main section.who .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 1rem;
    padding-bottom: 0;
  }
  main section.who .content div {
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0rem;
  }
  main section.who .content div p {
    margin-bottom: 0.5rem;
  }
  main section.who .content div ul {
    margin: 0;
    padding: 0.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0;
    width: calc(100% - 2rem);
  }
  main section.who .content div ul li {
    margin: 0 0.5rem 0.25rem;
    display: block;
    width: calc(33.3333333333% - 1rem);
    font-size: calc(0.5rem + 12 * (100vw - 300px) / 1300);
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
  }
  main section.who .content div ul li:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: calc(5rem + 12 * (100vw - 300px) / 1300);
    height: calc(5rem + 12 * (100vw - 300px) / 1300);
    border-radius: 100vmax;
  }
  main section.who .content div ul li:nth-of-type(1):before {
    background-image: url("../img/who/who-1.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  main section.who .content div ul li:nth-of-type(2):before {
    background-image: url("../img/who/who-2.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  main section.who .content div ul li:nth-of-type(3):before {
    background-image: url("../img/who/who-3.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  main section.who .content div ul li:nth-of-type(4):before {
    background-image: url("../img/who/who-4.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  main section.who .content div ul li:nth-of-type(5):before {
    background-image: url("../img/who/who-5.png");
    background-size: 50%;
    background-position: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: rgba(51, 62, 72, 0.5);
  }
  main section.who .three {
    opacity: 0.3;
  }
  main section.teams {
    padding-top: 15vh;
    height: auto;
  }
  main section.teams .marquee-title {
    margin-top: 0;
    padding-top: 0;
    height: 2.2rem;
    font-size: 2rem;
  }
  main section.teams .marquee-title .marquee-txt {
    width: 3600px;
    height: 2.2rem;
    --offset: 450px;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    animation-name: marquee;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  main section.teams .marquee-title .marquee-txt span {
    width: 1800px;
  }
  main section.teams .marquee-title .marquee-txt span:nth-of-type(2) {
    left: 1800px;
  }
  main section.teams .content {
    height: auto;
  }
  main section.teams .content .col-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 1rem;
  }
  main section.teams .content .col-container .col:not(.team-pics) {
    position: relative;
    width: calc(80% - 2rem);
    z-index: 0;
    text-align: right;
  }
  main section.teams .content .col-container .col:not(.team-pics) p {
    margin-top: 1rem;
    text-align: center;
  }
  main section.teams .content .col-container .view-all {
    margin-top: 0;
    max-width: 80%;
  }
  main section.teams .content .col-container .view-all a {
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
    margin-top: 0;
  }
  main section.branches {
    padding-top: 10vh;
    height: auto;
  }
  main section.branches .content {
    height: 60vh;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0;
    margin-top: 10vh;
  }
  main section.branches .content div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0;
    height: 40%;
  }
  main section.branches .content div h3 {
    width: 80%;
  }
  main section.branches .content div .bldg-3, main section.branches .content div .bldg-4 {
    display: none;
  }
  main section.branches .branch-link {
    top: 0;
    padding-top: 30vh;
  }
  main section.branches .branch-link .col-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 0;
    height: 60vh;
  }
  main section.branches .branch-link .col-container .col {
    width: calc(100% - 2rem);
  }
  main section.branches .branch-link .col-container .col a {
    padding: 1rem;
    width: 80%;
    margin-top: 0;
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
    text-align: center;
  }
  main section.clients {
    padding-top: 10vh;
    height: 60vh;
  }
  main section.clients .content {
    margin-top: 5rem;
    width: 80%;
    height: 60vh;
  }
  main section.clients .content .text-content {
    margin-top: 10vh;
    width: 100%;
    max-width: 100%;
  }
  main section.clients .content .slider {
    width: 100vw;
    margin-top: 5rem;
  }
  main section.clients .content .slider .slider-inner {
    padding: 0;
    top: calc(50vh - 50px);
    height: calc(33.3333333333vw - 1rem);
    width: 100%;
    margin-left: 0;
  }
  main section.clients .content .slider .slider-inner .slick-slide,
  main section.clients .content .slider .slider-inner .item-overlay {
    width: calc(33.3333333333vw - 1rem);
    height: calc(33.3333333333vw - 1rem);
  }
  main section.clients .content .slider .slider-inner .slick-slide .img,
  main section.clients .content .slider .slider-inner .item-overlay .img {
    width: 100%;
  }
  main section.clients .content .arrow-left, main section.clients .content .arrow-right {
    top: calc(50vh - 50px + 5rem);
    width: 50px;
    height: calc(33.3333333333vw - 1rem);
    background-size: 20px;
  }
  main section.clients .content .arrow-left:hover, main section.clients .content .arrow-right:hover {
    background-color: transparent;
  }
  main section.works {
    padding-top: 10vh;
    height: 100vh;
  }
  main section.works .content {
    margin-top: 10vh;
  }
  main section.works .content .text-content {
    width: 80%;
    max-width: 80%;
  }
  main section.works .content .slider {
    margin-top: 5vh;
    width: 100vw;
  }
  main section.works .content .slider .slider-inner {
    padding: 0;
    top: calc(40vh - 50px);
    margin: 0;
    width: 100%;
    height: 30%;
    justify-content: space-around;
  }
  main section.works .content .slider .slider-inner .slick-slide,
  main section.works .content .slider .slider-inner .item-overlay {
    width: 100vw;
    height: 300px;
  }
  main section.works .content .slider .slider-inner .slick-slide .img,
  main section.works .content .slider .slider-inner .item-overlay .img {
    left: 0;
    width: 100%;
    filter: none;
    background-position: center;
  }
  main section.works .content .slider .slider-inner .slick-slide .bubble,
  main section.works .content .slider .slider-inner .item-overlay .bubble {
    display: none;
  }
  main section.works .content .arrow-left, main section.works .content .arrow-right {
    top: calc(40vh - 50px);
    margin-top: 5vh;
    width: 50px;
    height: 30%;
    background-size: 20px;
  }
  main section.works .content .arrow-left:hover, main section.works .content .arrow-right:hover {
    background-color: transparent;
  }
  main section.contact {
    padding-top: 10vh;
    height: 100vh;
    background-image: url("../img/grid-2.svg");
    background-size: 50px 50px;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: repeat;
    background-color: #333e48;
  }
  main section.contact .content {
    position: relative;
    margin-top: 0;
    height: auto;
    z-index: 2;
  }
  main section.contact .content .form-content {
    margin-top: 0;
  }
  main section.contact .content .form-content p {
    width: 80%;
  }
  main section.contact .content .btn {
    margin-top: 1rem;
  }
  main section.contact:before, main section.contact:after {
    content: "";
    position: absolute;
    display: block;
    inset: 0;
    background-image: linear-gradient(#333e48, #1a1a1a, #1a1a1a);
    background-size: 100%;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-color: none;
    z-index: 1;
  }
  main section.contact:after {
    background-image: url("../img/grid-2.svg");
    background-size: 50px 50px;
    background-position: initial;
    background-attachment: inherit;
    background-repeat: repeat;
    background-color: transparent;
  }
  footer {
    width: calc(100% - 2rem);
    height: auto;
    max-height: initial;
    padding-bottom: 10vh;
  }
  footer .map {
    height: 30vh;
  }
  footer .content {
    padding-top: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
  }
  footer .content .side-col:nth-of-type(1) img {
    width: 100%;
  }
  footer .content .side-col:nth-of-type(3) {
    padding: 1rem;
    width: calc(100% - 2rem);
    border: none;
  }
  footer .content .side-col:nth-of-type(3) ul {
    display: none;
  }
  footer .content .side-col:nth-of-type(3) .return {
    display: block;
    padding: 0.5rem;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border-radius: 100vmax 100vmax 100vmax 0;
    background-color: #96c940;
    color: #333e48;
  }
  footer .content .mid-col {
    margin-top: 0;
    padding: 0 1rem;
    width: calc(80% - 2rem);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
  }
  footer .content .mid-col .col {
    padding: 0 1rem;
    width: 100%;
  }
  footer .content .mid-col .col p.title, footer .content .mid-col .col p.address {
    margin-bottom: 0.5rem;
  }
  footer .content .mid-col .col.twin .address {
    padding: 0;
  }
  footer .content .mid-col .col.twin:nth-of-type(1) {
    padding-right: 1rem;
  }
  footer .content .mid-col .col.twin:nth-of-type(2) {
    padding-left: 1rem;
  }
  footer .content .mid-col .col.twin:nth-of-type(2) .title {
    display: none;
  }
  footer .bot-content {
    margin-top: 0;
  }
  footer > * {
    flex-grow: 1;
    flex-basis: 100%;
  }
}
@media (min-width: 1600px) {
  main.inner section.contact .content p {
    width: 1200px;
  }
}
@media (max-width: 1024px) {
  :root {
    --base-font-size: 14px;
  }
}
@media (max-width: 768px) {
  :root {
    --base-font-size: 16px;
  }
  body.inner main.inner section.banner:before {
    opacity: 0.8;
    mix-blend-mode: multiply;
  }
  body.inner main.inner section.banner:before, body.inner main.inner section.banner:after {
    width: 100%;
  }
  body.inner main.inner section.banner.about-us:after {
    background-image: url("../img/./about-us/about-us.png");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.about-us-process:after {
    background-image: url("../img/./about-us/process.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.about-us-team:after {
    background-image: url("../img/./about-us/team.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.services:after {
    background-image: url("../img/./services/services.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.singapore:after {
    background-image: url("../img/./branches/singapore-banner.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.shanghai:after {
    background-image: url("../img/./branches/shanghai-banner.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.manila:after {
    background-image: url("../img/./branches/manila-banner.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.work:after {
    background-image: url("../img/./works.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner.careers:after {
    background-image: url("../img/./careers.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: initial;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  body.inner main.inner section.banner .content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0;
  }
  body.inner main.inner section.banner .content .col-left {
    width: calc(100% - 4rem);
  }
  body.inner main.inner section.banner .content .col-left > div {
    max-width: calc(100% - 2rem);
  }
  body.inner main.inner section.banner .content .col-left > div h1 {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  body.inner main.inner section.banner .content .col-left > div p {
    text-align: center;
  }
  body.inner main.inner section.banner .content .col-left > div a {
    display: block;
    text-align: center;
  }
  body.inner main.inner section.container {
    padding: 3rem 0;
  }
  body.inner main.inner section.container .content {
    padding: 2rem 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
  }
  body.inner main.inner section.container .content.top-align {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
  }
  body.inner main.inner section.container .content .col {
    padding: 0 2rem;
    width: calc(100% - 4rem);
  }
  body.inner main.inner section.container .content .col.left-minor, body.inner main.inner section.container .content .col.right-minor, body.inner main.inner section.container .content .col.left-major, body.inner main.inner section.container .content .col.right-major {
    width: calc(100% - 4rem);
  }
  body.inner main.inner section.container .content .col.left-minor.no-padding, body.inner main.inner section.container .content .col.right-minor.no-padding, body.inner main.inner section.container .content .col.left-major.no-padding, body.inner main.inner section.container .content .col.right-major.no-padding {
    width: 100%;
  }
  body.inner main.inner section.container .content .col.left-minor.no-padding img.our-process-cta,
  body.inner main.inner section.container .content .col.left-minor.no-padding img.our-team-cta, body.inner main.inner section.container .content .col.right-minor.no-padding img.our-process-cta,
  body.inner main.inner section.container .content .col.right-minor.no-padding img.our-team-cta, body.inner main.inner section.container .content .col.left-major.no-padding img.our-process-cta,
  body.inner main.inner section.container .content .col.left-major.no-padding img.our-team-cta, body.inner main.inner section.container .content .col.right-major.no-padding img.our-process-cta,
  body.inner main.inner section.container .content .col.right-major.no-padding img.our-team-cta {
    width: 5rem;
  }
  body.inner main.inner section.container .content .col h3 {
    text-align: center;
  }
  body.inner main.inner section.container .content .col p {
    width: calc(100% - 2rem);
  }
  body.inner main.inner section.container .content .col p[data-timeline]:before {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    text-align: center;
    transform: none;
    font-size: calc(5rem + 12 * (100vw - 300px) / 1300);
  }
  body.inner main.inner section.container .content .col img {
    width: calc(100% - 2rem);
  }
  body.inner main.inner section.container .content .col .serv {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0;
  }
  body.inner main.inner section.container .content .col .serv .img-container {
    width: 5rem;
  }
  body.inner main.inner section.container .content .col .serv .img-container img {
    width: 100%;
  }
  body.inner main.inner section.container .content .col .serv .text-container {
    width: calc(100% - 7rem);
  }
  body.inner main.inner section.container .content .col p {
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
  }
  body.inner main.inner section.container .content .col p.trans {
    text-align: center;
  }
  body.inner main.inner section.container .content .col > div {
    width: 100%;
  }
  body.inner main.inner section.container .content.mid-line:before, body.inner main.inner section.container .content.mid-line-dash:before {
    display: none;
  }
  body.inner main.inner section.container .content.timeline {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
    height: auto;
  }
  body.inner main.inner section.container .content.timeline .col.left-minor {
    margin-bottom: 2rem;
    width: calc(100vw - 4rem);
    height: calc(100vw - 4rem);
  }
  body.inner main.inner section.container .content.timeline .col.left-minor.merlion .icon, body.inner main.inner section.container .content.timeline .col.left-minor.oriental-pearl .icon, body.inner main.inner section.container .content.timeline .col.left-minor.rizal .icon, body.inner main.inner section.container .content.timeline .col.left-minor.kuala .icon, body.inner main.inner section.container .content.timeline .col.left-minor.cryto .icon, body.inner main.inner section.container .content.timeline .col.left-minor.iot .icon {
    background-size: 100%;
    background-attachment: inherit;
  }
  body.inner main.inner section.container .content img.modal {
    margin-bottom: 1rem;
    width: calc(50% - 1rem);
  }
  body.inner main.inner section.container p {
    margin: 0;
    padding: 0 2rem;
  }
  body.inner main.inner section.container.services {
    padding: 2rem 0;
  }
  body.inner main.inner section.container.services .content {
    padding: 0 1rem;
  }
  body.inner main.inner section.container.green .content {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0;
  }
  body.inner main.inner section.container.green .content .col {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0;
  }
  body.inner main.inner section.container.lgray-2 {
    padding: 0;
  }
  body.inner main.inner section.container.lgray-2 .content {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 0;
    height: auto;
  }
  body.inner main.inner section.container.lgray-2 .content .col.left-major {
    padding: 1rem 2rem;
  }
  body.inner main.inner section.container.works-grid .grid-col {
    flex-basis: 50%;
  }
  body.inner main.inner section.contact h1 {
    margin-top: 5rem;
  }
  body.inner main.inner section.contact .content > div {
    padding-top: 7rem;
    margin-top: 0;
  }
  body.inner main.inner section.contact .content .form-content {
    padding-top: 2rem;
  }
  body.inner main.inner section.contact.cta {
    padding-top: 0;
  }
  body.inner main.inner section.contact.cta .content {
    margin-bottom: 0;
  }
  body.inner main.inner section.contact.cta .content div {
    padding: 2rem 0 2rem;
  }
  body.inner main.inner section.contact.cta .content div p {
    width: 80%;
  }
  body.inner main.inner section.contact.cta .content div a {
    width: 100%;
    max-width: 50vw;
  }
  body.inner main.inner section.contact.cta .map {
    margin-top: 0;
  }
  body.inner main.inner .btn {
    width: 100%;
    max-width: 50vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: calc(1rem + 12 * (100vw - 300px) / 1300);
  }
  body.inner footer .content .side-col:nth-of-type(1) {
    display: block;
  }
  body.inner footer .content .side-col:nth-of-type(1) img {
    width: 100%;
  }
  body.inner footer .content .mid-col {
    margin-top: 0;
  }
  body.inner footer .content .mid-col .col:nth-of-type(1) {
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  :root {
    --base-font-size: 14px;
  }
}
@media (max-width: 768px) {
  :root {
    --base-font-size: 16px;
  }
  @keyframes scroll-indicator-line-mobile {
    from {
      bottom: 1rem;
      height: 2.3rem;
    }
    to {
      bottom: -3rem;
      height: 5rem;
    }
  }
  @keyframes scroll-indicator-arrow-mobile {
    from {
      bottom: -1.2rem;
    }
    to {
      bottom: -2.5rem;
    }
  }
}