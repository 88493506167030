$media-string: "only screen and ";
$smallmobile: $media-string+"(max-width: 320px)";
$tablet: $media-string+"(max-width: 768px)";
$large-tablet: $media-string+"(max-width: 1024px)";
$large-desktop: $media-string+"(min-width: 1200px)";
$larger-desktop: $media-string+"(min-width: 1400px)";
$largest-desktop: $media-string+"(min-width: 1860px)";
$l4k-desktop: $media-string+"(min-width: 3000px)";

$media-tablet: 640px;
$media-largeTablet: 768px;
$media-desktop: 1024px;
$media-largeDesktop: 1440px;

@media (max-width: $media-desktop) {
	:root{
		--base-font-size: 14px;
	}

}

@media (max-width: $media-largeTablet) {
	:root{
		--base-font-size: 16px;
	}

	@keyframes scroll-indicator-line-mobile{
		from{
			bottom: 1rem;
			height: 2.3rem;
		}to{
			bottom: -3rem;
			height: 5rem;
		}
	}

	@keyframes scroll-indicator-arrow-mobile{
	from{
		bottom: -1.2rem;
	}to{
		bottom: -2.5rem;
	}
}
}