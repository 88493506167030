main.inner{
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
	
	section.banner{
		width: 100vw;
		height: 100vh;
		&:before,&:after{
			content: '';
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: calc(50% + 200px);
			height: 100%;
			@include bg-image('grid-2.svg',50px 50px,inherit,initial,repeat);
			background-color: $c3-gray;
			z-index: -1;
			mix-blend-mode: multiply;
		}
		&:after{
			width: 100%;
			height: 100%;
			background-color: transparent;
			filter: grayscale(100%) blur(5px);
		}

		&.about-us:after{
			@include bg-image('./about-us/about-us.png',120%,fixed,center);
		}
		&.about-us-process:after{
			@include bg-image('./about-us/process.jpg',120%,fixed,center);
		}
		&.about-us-team:after{
			@include bg-image('./about-us/team.jpg',120%,fixed,center);
		}
		&.services:after{
			@include bg-image('./services/services.jpg',120%,fixed,center);
		}
		&.branch:after{
			@include bg-image('./branches/banner.jpg',cover,fixed,center);
		}
		&.work:after{
			@include bg-image('./works.jpg',120%,fixed,center);
		}
		&.careers:after{
			@include bg-image('./careers.jpg',120%,fixed,center);
		}
		.content{
			margin-top: 5rem;
			width: 100%;
			max-width: 1200px;
			height: calc(100vh - 5rem);
			.col-left{
				@include flex("row-center-center",nowrap,0);
				padding: 2rem;
				width: calc(((100% / 3) * 2) - 4rem);
				>div{
					display: inline-block;
					max-width: 30rem;
					text-align: center;
					h1{
						position: relative;
						margin-bottom: 2rem;
						padding: 0;
						color: #fff;
						text-align: center;
					}
					p{
						margin:0 auto;
						width: 100%;
						font-weight: 300;
						color: #fff;
						text-align: center;

						span{
							font-weight: 700;
							text-transform: uppercase;
							color: $c3-green;
						}
					}
					a{
						font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
						text-align: center;
						text-transform: uppercase;
						color: $c3-green;
					}
				}
			}
		}
	}

	section.container{
		@include bg-image('grid.svg',50px 50px,fixed,initial,repeat);
		width: 100vw;
		height: 100%;
		.content{
			position: relative;
			padding: 2rem 0;
			height: 100%;
			isolation: isolate;
			@include flex("row-center-center",wrap,0);
			&.top-align{
				@include flex("row-center-top",nowrap,0);
			}
			.col,.full{
				position: relative;
				@include flex("row-center-top",nowrap,0);
				padding: 2rem;
				width: calc(50% - 4rem);
				height: auto;
				isolation: isolate;
				&.left-minor,&.right-minor{
					width: calc((100% / 3) - 4rem);
					&.no-padding{
						@include flex("column-center-left",nowrap,0);
						padding: 0;
						width: calc(100% / 3);
						height: 100%;
					}
				}
				&.left-major,&.right-major{
					@include flex("column-top-center",nowrap,0);
					width: calc(((100% / 3) * 2) - 4rem);
				}
				.serv{
					@include flex("row-center-center",nowrap,0);
					padding: 1rem;
					width: 100%;
					.img-container{
						padding: 1rem;
						width: 10rem;
						background-color: $c3-green;
						border-radius: 100vmax;
						img{
							width: 8rem;
							object-fit: scale-down;
						}
					}
					p{
						padding: 0 1rem;
						background-color: transparent;
						strong{
							font-size: calc(1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
						}
					}
				}
				h4{
					display: block;
					width: 100%;
				}
				p{
					position: relative;
					margin:0;
					padding: 1rem;
					width: calc(100% - 2rem);
					background-color: #fff;
					font-size: calc(.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
					// text-align: center;
					isolation: isolate;
					&.trans{
						background-color: transparent;
					}
					strong{
						text-transform: uppercase;
					}
					span{
						font-weight: 700;
						color: $c3-green;
					}
					&[data-timeline]:before{
						content: attr(data-timeline);
						position: absolute;
						display: block;
						top:6rem;
						right: -5.5rem;
						width: 20rem;
						height: 8rem;
						text-align: center;
						font-size: calc(12rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
						font-weight: 900;
						color: $c3-green;
						line-height: 1;
						opacity:.2;
						transform: rotate(-90deg);
						z-index: -1;
					}
				}
				img{
					width: 100%;
					&.cir{
						object-fit: scale-down;
						border-radius: 100vmax;
						background-color: #fff;
					}
				}
				a.btn{
					flex: 1 1 100%;
					margin-top: 0;
				}
			}
			.full{
				width: 100%;
				max-width: 1200px;
				p{
					background-color: transparent;
				}
			}
			&.mid-line:before,
			&.mid-line-dash:before{
				content: '';
				position: absolute;
				top:0;
				left: calc(((100% / 3) * 2));
				width: 2px;
				height: 100%;
				background-color: rgb(61 52 72 / .2);
				z-index: -1;
			}
			&.mid-line-dash:before{
				top: 4rem;
				left: calc((1200px / 3) / 2);
				width: 0;
				height: 100%;
				border-left: dashed 2px $c3-gray;
				background-color: transparent;
			}
			&.timeline{
				max-width: 100%;
				@include flex("row-center-center",nowrap,0);
				height: 100vh;
				.col.left-minor{
					padding: 2rem;
					width: calc((100vw / 3) - 4rem);
					// max-width:calc(1200px / 3);
					height: calc((100vw / 3) - 4rem);
					max-height:calc(1200px / 2);
					.icon{
						width: 100%;
						height: 100%;
						border-radius: 1rem;
						border: solid 1rem #fff;
						@include shadow;
					}
					&.merlion .icon{
						@include bg-image('./about-us/merlion.jpg',50%,fixed, leftcenter);
					}
					&.oriental-pearl .icon{
						@include bg-image('./about-us/shanghai-towers.jpg',50%,fixed,left center);
					}
					&.rizal .icon{
						@include bg-image('./about-us/rizal.jpg',50%,fixed,left center);
					}
					&.kuala .icon{
						@include bg-image('./about-us/kuala.jpg',50%,fixed,left top);
					}
					&.cryto .icon{
						@include bg-image('./about-us/cryto.jpg',100%,fixed,center);
					}
					&.iot .icon{
						@include bg-image('./about-us/iot.jpg',50%,fixed,left center);
					}
				}
				img{
					position: absolute;
					top:50%;
					width: 50%;
				}
			}
			>img{
				width: calc(100% - 4rem);
				&.modal{
					margin: 0 .5rem;
					width: calc((100% / 3) - 1rem);
					cursor: pointer;
				}
			}
		}
		h3{
			padding: .5rem 0;
			background-color: #fff;
		}
		p{
			padding: 2rem;
		}
		ul{
			li{
				list-style: square;
				font-size: calc(.8em + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
				color: $c3-gray;
			}
		}
		&.green{
			@include bg-image('grid-4.svg',50px 50px,fixed,initial,repeat,$c3-green);
			.content{
				@include flex("row-top-center",nowrap,0);
				h3{
					background-color: transparent;
				}
			}
		}
		&.lgray-1{
			@include bg-image('grid-3.svg',50px 50px,fixed,initial,repeat,#F3F3F3);
		}
		&.lgray-2{
			background-image: none;
			background-color: #D9D9D9;
			.content{
				@include flex("row-center-center",nowrap,0);
				padding: 0;
				height: 50vh;
				.col{
					@include flex("row-center-center",nowrap,0);
					padding: 0 2rem;
					p{
						background-color: transparent;
					}
					a{
						position: relative;
						@include flex("row-center-center",nowrap,0);
						padding: 2rem;
						width: calc(100% - 4rem);
						height: 50%;
						font-weight: 700;
						text-transform: uppercase;
						background-color: $c3-green;
						color: #fff;
						overflow: hidden;
						isolation: isolate;
						&:before{
							content: '';
							position: absolute;
							display: block;
							background-color: $c3-dgray;
							top: calc(50% - 15rem);
							left:-40rem;
							width: 30rem;
							height: 30rem;
							transform: rotate(45deg);
							z-index: -1;

							transition: left .5s cubic-bezier(1, 0.08, 0.35, 1);
						}

						transition: all .5s cubic-bezier(1, 0.08, 0.35, 1);
						&:nth-of-type(1){
							height: calc(50% - 2px);
							border-bottom: solid 2px #D9D9D9;
						}
						&:hover{
							color: $c3-green;
							background-color: $c3-gray;
							&:before{
								left: -12rem;
							}
						}
					}
					img{
						width: 5rem;
					}
				}
			};	
		}
		&.works-grid{
			@include flex("row-center-top",wrap,1rem);
			padding: 1rem;
			margin: 0 auto; 
			width: calc(100% - 2rem);
			background-color: #F3F3F3;
			h3{
				margin: 0;
				width: 100%;
				background-color: transparent;
				text-align: center;
			}
			.grid-col{
				display: block;
				flex-grow: 1;
				flex-basis: 30%;
				flex-shrink: 1;
				margin-bottom: 1rem;
				width: 100%;
				max-width: 30rem;
				a{
					display: block;
					margin: 1rem 0;
					img{
						width: 100%;
					}
				}
			}
		}
	}

	section.careers-form{
		position: fixed;
		padding: 5rem 0;
		top:0;
		right: -100%;
		width: 100%;
		height: calc(100% - 10rem);
		background-color: #fff;
		overflow-y:auto;
		@include bg-image('grid.svg',50px 50px,fixed,initial,repeat,#FFFFFF);
		transition: right .5s cubic-bezier(1, 0.08, 0.35, 1);
		z-index: 99;
		.content{
			position: relative;
			height: 100%;
			@include flex("column-center-center",nowrap,0);
			h2,p{
				text-align: center;
			}
			.form-content{
				padding-top: 1rem;
			}	
			.back{
				position: absolute;
				top: 50%;
				left: 1rem;
				width: 70px;
				height: 70px;
				@include bg-image('arrow.svg',70% 70%,initial,center,no-repeat,#FFFFFF);
				cursor: pointer;
				transition: background-color .3s cubic-bezier(1, 0.08, 0.35, 1);
				&:hover{
					background-color: #eee;
				}
			}
		}
		&.show{
			right: 0;
		}
	}

	section.contact{
		width: 100vw;
		height: auto;
		h1{
			position: relative;
			margin-top: 10rem;
			margin-bottom: 0;
			color: #fff;
			text-align: center;
			z-index: 1;
		}
		.content{
			margin: 0 auto 1rem;
			height: auto;
			@include flex("row-center-center",nowrap,0);
			p{
				margin: 0 auto 2rem;
			}
			>div:not(.form-content){
				padding: 2rem 0;
			}
		}
	}

	section.thank-you{
		padding-top: 3rem;
		width: 100vw;
		height: 100vh;
		@include bg-image('grid.svg',50px 50px,fixed,initial,repeat);
		.content{
			@include flex("column-center-center",nowrap,0);
			padding: 0 2rem;
			width: calc(100% - 4rem);
			height: 100%;
			h1{
				color: $c3-green;
				text-align: center;
			}
			h2{
				text-align: center;
				font-size: calc(1.2em + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
				text-transform: none;
			}
			img{
				width: 100%;
				max-width: 10rem;
			}
		}
	}

	.btn{
		display: block;
		margin: 2rem auto 0;
		width: calc(100% - 4rem);
		max-width: 300px;
	}
}
